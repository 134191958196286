import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import dataService from 'api/data.service';
import VuiButton from 'components/VuiButton';
import { Typography, Grid, TextField, CircularProgress, Backdrop, Autocomplete } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VuiBox from 'components/VuiBox';
import DashboardLayout from 'utils/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'utils/Navbars/DashboardNavbar';
import Footer from 'utils/Footer';
import UpcomingEventCard from 'utils/Cards/EventCard/UpcomingEventCard';
import { ReactComponent as NotFound } from 'assets/svgs/sad.svg';
import VuiInput from 'components/VuiInput';
import { ReactComponent as Loading } from 'assets/svgs/loading.svg';

function Overview() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [profile, setProfile] = useState(null);
  const [pendingGames, setPendingGames] = useState([]);
  const [pendingCategories, setPendingCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState({
    date: searchParams.get('date') || '',
    games: searchParams.getAll('games') || [],
    categories: searchParams.getAll('categories') || [],
    bookmarked: searchParams.get('bookmarked') === 'true',
    participations: searchParams.get('participations') === 'true',
    ownerEvents: searchParams.get('ownerEvents') === 'true',
    pendingRequest: searchParams.get('pendingRequest') === 'true',
  });

  const observer = useRef();
  const lastEventRef = useRef();

  const fetchEvents = useCallback(async (page, filters, reset = false) => {
    setLoading(true);
    try {
      const data = await dataService.pendingEvents(page, 5, filters);
      setEvents(prevEvents => reset ? data.resources : [...prevEvents, ...data.resources]);
      setHasMore(data.resources.length > 0);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight && hasMore && !loading) {
      setPage(prevPage => prevPage + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    async function fetchData() {
      try {
        const [pendingGamesData, profileData] = await Promise.all([
          dataService.getPendingEventsGames(),
          dataService.getSessionProfile()
        ]);
        setPendingGames(pendingGamesData.resources);
        setProfile(profileData.resource);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchPendingCategories() {
      try {
        const pendingCategoriesData = await dataService.getPendingCategoriesGames();
        setPendingCategories(pendingCategoriesData.resources);
      } catch (error) {
        console.error('Error fetching unique categories:', error);
        setError(error.message);
      }
    }
    fetchPendingCategories();
  }, []);

  useEffect(() => {
    fetchEvents(page, filters, page === 1);
  }, [page, filters, fetchEvents]);

  useEffect(() => {
    setSelectedDate(filters.date);
  }, [filters.date]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleFilterChange = (key, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const [selectedDate, setSelectedDate] = useState(filters.date);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    filters['date'] = event.target.value;
  };

  const applyFilters = () => {
    const newSearchParams = new URLSearchParams();
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        filters[key].forEach(val => newSearchParams.append(key, val));
      } else if (filters[key]) {
        newSearchParams.set(key, filters[key]);
      }
    });
    history.push({ search: newSearchParams.toString() });
    setPage(1);
    setLoading(true);
    fetchEvents(1, filters, true);
  };

  const clearFilters = () => {
    setFilters({
      date: '',
      games: [],
      categories: [],
      bookmarked: false,
      participations: false,
      ownerEvents: false,
      pendingRequest: false,
    });
    setPage(1);
    setLoading(true);
    fetchEvents(1, {
      date: '',
      games: [],
      categories: [],
      bookmarked: false,
      participations: false,
      ownerEvents: false,
      pendingRequest: false,
    }, true);
  };

  const formatDateToISO = (dateString) => {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  };

  if (loading && page === 1) {
    return (
      <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return <div>{t('error', { error })}</div>;
  }

  const handleCreateEvent = () => {
    history.push('/create-event');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={5} mb={3}>
        <VuiBox mb={3} display="flex" alignItems="center" flexWrap="wrap" sx={{ gap: 1 }}>
          <Autocomplete
            multiple
            size="small"
            options={pendingGames}
            getOptionLabel={(option) => option.name}
            value={filters.games}
            onChange={(event, value) => handleFilterChange('games', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('games')}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  sx: {
                    color: 'light',
                    fontSize: '15px',
                    transform: 'none',
                    marginTop: '-35px',
                  },
                }}
              />
            )}
            sx={{
              flex: 1,
              minWidth: '200px', // Ajusta el ancho mínimo según sea necesario
              maxWidth: '400px', // Ajusta el ancho máximo según sea necesario
            }}
          />
          <Autocomplete
            multiple
            size="small"
            options={pendingCategories}
            getOptionLabel={(option) => t(`categories.${option.name}`)}
            value={filters.categories}
            onChange={(event, value) => handleFilterChange('categories', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('categoriesLabel')}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  sx: {
                    color: 'light',
                    fontSize: '15px',
                    transform: 'none',
                    marginTop: '-35px',
                  },
                }}
              />
            )}
            sx={{
              flex: 1,
              minWidth: '200px', // Ajusta el ancho mínimo según sea necesario
              maxWidth: '400px', // Ajusta el ancho máximo según sea necesario
            }}
          />
          <VuiInput
            mt={2}
            mb={2}
            type="date"
            placeholder={t('selectDate')}
            name="date"
            defaultValue={filters.date}
            value={selectedDate}
            onChange={handleDateChange}
            size="small"
            sx={{
              minWidth: 'unset',
              mr: 1,
              maxWidth: '150px !important',
              '& input': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#001540',
                },
                '&:hover fieldset': {
                  borderColor: '#001540',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#001540',
                },
              },
              '& .MuiInputAdornment-root': {
                color: 'white !important',
              },
              '& input[type="date"]::-webkit-calendar-picker-indicator': {
                filter: 'invert(1)',  // Cambia el color del icono a blanco
              },
              '& input::placeholder': {
                color: 'white',
                opacity: 1,
              },
            }}
          />
          <VuiButton
            variant="contained"
            color="success"
            startIcon={<CheckCircleIcon fontSize="small" />}
            onClick={applyFilters}
            size="small"
            sx={{
              minWidth: 'unset',
              flex: 1,
              maxWidth: '150px',
            }}
          >
            {t('applyFilters')}
          </VuiButton>
          <VuiButton
            variant="outlined"
            color="warning"
            variant="contained"
            onClick={clearFilters}
            size="small"
            sx={{
              minWidth: 'unset',
              flex: 1,
              maxWidth: '150px',
            }}
          >
            {t('clearFilters')}
          </VuiButton>
        </VuiBox>
        <hr/>
        <VuiBox mt={2} mb={2} display="flex" alignItems="center" flexWrap="wrap">
          <VuiButton
            variant={!filters.bookmarked && !filters.participations && !filters.ownerEvents && !filters.pendingRequest ? "contained" : "outlined"}
            color="light"
            onClick={() => {
              setFilters({
                ...filters,
                bookmarked: false,
                participations: false,
                ownerEvents: false,
                pendingRequest: false,
              });
              setPage(1);
              setLoading(true);
              fetchEvents(1, filters, true);
            }}
            sx={{ mr: 1 }}
          >
            {t('all')}
          </VuiButton>
          <VuiButton
            variant={filters.pendingRequest ? "contained" : "outlined"}
            color="light"
            onClick={() => {
              setFilters(prevFilters => ({
                ...prevFilters,
                pendingRequest: !prevFilters.pendingRequest,
                participations: false,
                ownerEvents: false,
                bookmarked: false
              }));
              setPage(1);
              setLoading(true);
              fetchEvents(1, { ...filters, pendingRequest: !filters.pendingRequest, participations: false, ownerEvents: false, bookmarked: false }, true);
            }}
            sx={{ mr: 1 }}
          >
            {t('pending')}
          </VuiButton>
          <VuiButton
            variant={filters.participations ? "contained" : "outlined"}
            color="light"
            onClick={() => {
              setFilters(prevFilters => ({
                ...prevFilters,
                participations: !prevFilters.participations,
                bookmarked: false,
                ownerEvents: false,
                pendingRequest: false
              }));
              setPage(1);
              setLoading(true);
              fetchEvents(1, { ...filters, participations: !filters.participations, bookmarked: false, ownerEvents: false, pendingRequest: false }, true);
            }}
            sx={{ mr: 1 }}
          >
            {t('attends')}
          </VuiButton>
          <VuiButton
            variant={filters.bookmarked ? "contained" : "outlined"}
            color="light"
            onClick={() => {
              setFilters(prevFilters => ({
                ...prevFilters,
                bookmarked: !prevFilters.bookmarked,
                participations: false,
                ownerEvents: false,
                pendingRequest: false
              }));
              setPage(1);
              setLoading(true);
              fetchEvents(1, { ...filters, bookmarked: !filters.bookmarked, participations: false, pendingRequest: false, ownerEvents: false }, true);
            }}
            sx={{ mr: 1 }}
          >
            {t('bookmarks')}
          </VuiButton>
          <VuiButton
            variant={filters.ownerEvents ? "contained" : "outlined"}
            color="light"
            onClick={() => {
              setFilters(prevFilters => ({
                ...prevFilters,
                ownerEvents: !prevFilters.ownerEvents,
                participations: false,
                pendingRequest: false,
                bookmarked: false
              }));
              setPage(1);
              setLoading(true);
              fetchEvents(1, { ...filters, ownerEvents: !filters.ownerEvents, participations: false, pendingRequest: false, bookmarked: false }, true);
            }}
            sx={{ mr: 1 }}
          >
            {t('ownerEvents')}
          </VuiButton>
          {/*
            <VuiButton
              color="success"
              variant="contained"
              onClick={handleCreateEvent}
            >
              {t(`routes.create-event`)}
            </VuiButton>
          */}
        </VuiBox>
        <Grid  
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          {events.length > 0 ? (
            events.map((event, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={12} xl={12}>
                  <UpcomingEventCard
                    ref={events.length - 1 === index ? lastEventRef : null}
                    image={event.image}
                    title={event.name}
                    event_date={event.event_date}
                    description={event.description}
                    visible={event.visible}
                    event_type={event.event_type}
                    players={event.players}
                    games={event.games}
                    owner={event.owner}
                    owner_id={event.owner_id}
                    event_id={event.event_id}
                    event_bookmarked={event.bookmarked_by_user}
                    languages={event.languages}
                    actions={[
                      {
                        type: "internal",
                        route: `/event/${event.event_id}`,
                        color: "light",
                        label: t('see'),
                        icon: <InfoIcon />,
                      }
                    ]}
                    options={[]}
                  />
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <VuiBox mt={12} textAlign="center" xs={12} md={12} xl={12} color="light">
              <NotFound style={{ width: '55%', height: '55%' }} />
              <Typography variant="h3" sx={{ color: 'white !important' }}>{t('noEventData')}</Typography>
            </VuiBox>
          )}
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
