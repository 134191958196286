import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_MEEPLE_FREAKS + '/api',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Request-Method': 'GET, POST, PUT, DELETE, OPTIONS'
  }
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token !== null && typeof token !== "undefined") {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

const dataService = {
  getSessionProfile: async () => {
    try {
      const response = await api.get('/sessions/profile')
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  // GAMES
  discoverGames: async (page = 1, perPage = 5, filter = "") => {
    try {
      const response = await api.get('/games/discover', {
        params: {
          currentPage: page,
          perPage: perPage,
          filter: filter
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  collectionGames: async (page = 1, perPage = 10) => {
    try {
      const response = await api.get('/games/collection', {
        params: {
          currentPage: page,
          perPage: perPage
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  wishlist: async (page = 1, perPage = 10) => {
    try {
      const response = await api.get('/games/wishlist', {
        params: {
          currentPage: page,
          perPage: perPage
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getGameById: async (id) => {
    try {
      const response = await api.get(`/games/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  setUserPopularity: async (id, actionType) => {
    try {
      await api.post(`/games/${id}/popularity`, { action_type: actionType })
    } catch (error) {
      console.error(`Error ${actionType} the game:`, error);
    }
  },
  //USERS
  getUserProfile: async () => {
    try {
      const response = await api.get('/users/profile')
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getUserRating: async () => {
    try {
      const response = await api.get('/users/rating')
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getProfileLastEvents: async () => {
    try {
      const response = await api.get('/users/last-events')
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getUserEventsThisMonth: async () => {
    try {
      const response = await api.get('/users/events-this-month')
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getEvents: async (page = 1, perPage = 10, status = 'all') => {
    try {
      const response = await api.get('/events/all', {
        params: {
          currentPage: page,
          perPage: perPage,
          status: status
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getEventById: async (id) => {
    try {
      const response = await api.get(`/events/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  addEventNote: async(id, note) => {
    try {
      const response = await api.post(`/events/${id}/add-note`, {
        note: note 
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  joinEvent: async(id, join) => {
    try {
      const response = await api.post(`/events/${id}/join`, {
        join: join 
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  bookmarkEvent: async(id, action) => {
    try {
      const response = await api.post(`/events/${id}/bookmark`, {
        action_type: action 
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  pendingEvents: async (page = 1, perPage = 10, filter = "") => {
    try {
      const response = await api.get('/events/pendings', {
        params: {
          currentPage: page,
          perPage: perPage,
          filter: filter
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getPendingEventsGames: async () => {
    try {
      const response = await api.get('/events/pending-games');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  getPendingCategoriesGames: async () => {
    try {
      const response = await api.get('/events/pending-categories');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  giveMeTheMoney: async () => {
    try {
      const response = await api.get('/users/give-me-the-money')
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getUserById: async(id) => {
    try {
      const response = await api.get(`/users/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }    
  },
  getUserLastEvents: async(id) => {
    try {
      const response = await api.get(`/users/${id}/friend-events`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }    
  },
  sendFriendRequest: async(id) => {
    try {
      const response = await api.put(`/users/${id}/send-friend-request`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }    
  },
  cancelFriendRequest: async(id) => {
    try {
      const response = await api.put(`/users/${id}/cancel-friend-request`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }    
  },
  acceptFriendRequest: async(id) => {
    try {
      const response = await api.put(`/users/${id}/accept-friend-request`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }    
  },
  getFriendRequestStatus: async(id) => {
    try {
      const response = await api.get(`/users/${id}/friend-request`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }    
  },
  userCollectionGames: async (id, page = 1, perPage = 10) => {
    try {
      const response = await api.get('/games/collection', {
        params: {
          friend: id,
          currentPage: page,
          perPage: perPage
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  userWishlist: async (id, page = 1, perPage = 10) => {
    try {
      const response = await api.get('/games/wishlist', {
        params: {
          friend: id,
          currentPage: page,
          perPage: perPage
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getFriends: async (page = 1, perPage = 10, name = '') => {
    try {
      const response = await api.get('/users/friends', {
        params: {
          currentPage: page,
          perPage: perPage,
          name: name
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getPendingFriendRequests: async (page = 1, perPage = 10) => {
    try {
      const response = await api.get('/users/pending-friends-request');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  getAnnouncements: async () => {
    try {
      const response = await api.get('/announcements');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  getNotifications: async () => {
    try {
      const response = await api.get('/notifications');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  readedNotification: async (id) => {
    try {
      const response = await api.put(`/notifications/${id}/mark-readed`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  createEvent: async (eventData) => {
    try {
      const response = await api.post('/events/user-creation', {
        data: eventData,
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  updateEvent: async (id, eventData) => {
    try {
      const response = await api.post(`/events/${id}/user-update`, {
        data: eventData,
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  deleteEvent: async (id, eventData) => {
    try {
      const response = await api.delete(`/events/${id}/user-delete`)
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  acceptParticipantRequest: async(eventId, participantId) => {
    try {
      const response = await api.post(`/events/${eventId}/player-request`, {
        user: participantId,
        status: 'accepted' 
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  rejectParticipantRequest: async(eventId, participantId) => {
    try {
      const response = await api.post(`/events/${eventId}/player-request`, {
        user: participantId,
        status: 'rejected' 
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  pendingParticipantRequest: async(eventId, participantId) => {
    try {
      const response = await api.post(`/events/${eventId}/player-request`, {
        user: participantId,
        status: 'pending' 
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  removeParticipantRequest: async(eventId, participantId) => {
    try {
      const response = await api.post(`/events/${eventId}/delete-player-request`, {
        user: participantId,
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  clearAllNotifications: async () => {
    try {
      const response = await api.put(`/notifications/clear-all`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  finishEvent: async (id) => {
    try {
      const response = await api.put(`/events/${id}/finish`)
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  finishAndRepeatEvent: async (id) => {
    try {
      const response = await api.put(`/events/${id}/finish-and-repeat`)
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  cancelEvent: async (id) => {
    try {
      const response = await api.put(`/events/${id}/cancel`)
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  rateEvent: async (eventId, rate) => {
    try {
      const response = await api.post(`/events/${eventId}/rate`, {
        rate: rate,
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
  updateScores: async(eventId, participants) => {
    try {
      const response = await api.post(`/events/${eventId}/update-scores`, {
        participants: participants
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  updateUserProfile: async(profile) => {
    try {
      const response = await api.post(`/users/update-profile`, {
        profile: profile
      })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  },
  deleteUserAccount: async () => {
    try {
      const response = await api.delete(`/users/delete-account`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  },
};

export default dataService;
