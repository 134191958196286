import React, { useState, useEffect } from 'react';
import VuiButton from "components/VuiButton";
import VuiBadge from "components/VuiBadge";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import dataService from "api/data.service";
import { Avatar } from '@mui/material';
import VuiTypography from "components/VuiTypography";
import { useHistory } from 'react-router-dom';

const FriendRequestButton = ({ userId, currentUserId, name, showViewButton, avatar }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [friendRequestStatus, setFriendRequestStatus] = useState(null);
  const [receiverRequest, setReceiverRequest] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const fetchFriendRequestStatus = async () => {
      try {
        const data = await dataService.getFriendRequestStatus(userId);
        if (data.resource !== null) {
          setReceiverRequest(data.resource.user_id);
          setFriendRequestStatus(data.resource.status);
        }
      } catch (error) {
        console.error("Error fetching friend request status:", error);
        setFriendRequestStatus(null); // Set status to null if there's an error or no request
      }
    };

    fetchFriendRequestStatus();
  }, [userId]);

  const handleRequest = async (action) => {
    try {
      if (action === 'cancel') {
        let actionData = await dataService.cancelFriendRequest(userId);
        setFriendRequestStatus(null);
        setReceiverRequest(null);
      } 
      if (action === 'confirm') {
        let actionData = await dataService.sendFriendRequest(userId);
        setFriendRequestStatus('pending');
        setReceiverRequest(userId);
      } else if (action === 'accept') {
        let actionData = await dataService.acceptFriendRequest(userId);
        setFriendRequestStatus('accepted');
      }
      setSnackbarOpen(true);
      setOpen(false);
      if(action === 'accept' || action === 'cancel'){
        window.location.reload();
      }
    } catch (error) {
      console.error("Error handling friend request:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleViewProfile = () => {
    history.push(`/user/${userId}`);
  };

  const getBadgeContent = () => {
    if (friendRequestStatus === 'accepted') {
      return t('friends');
    } else if (friendRequestStatus === 'pending') {
      return t('pending');
    }
    return null;
  };

  const getBadgeColor = () => {
    if (friendRequestStatus === 'accepted') {
      return 'success';
    } else if (friendRequestStatus === 'pending') {
      return 'primary';
    }
    return 'default';
  };

  if (userId === currentUserId) {
    return null; // No renderizar botones si el usuario ve su propia página
  }

  return (
    <>
      <Tooltip>
        <VuiButton
          variant="contained"
          color={friendRequestStatus === 'pending' ? 'primary' : 'success'}
          startIcon={<PersonAddIcon fontSize="small" />}
          onClick={handleOpen}
          size="small"
          sx={{ minWidth: 'unset' }}
        >
          {receiverRequest === currentUserId || friendRequestStatus === 'accepted' ? (
            t('manageFriendship')
          ) : (
            friendRequestStatus === 'pending' ? t('cancelFriendRequest') : t('sendFriendRequest')
          )}
        </VuiButton>
        {friendRequestStatus !== null && showViewButton !== "true" && (
          <VuiBadge
            badgeContent={getBadgeContent()}
            size="xs"
            color={getBadgeColor()}
            container
            sx={{ marginLeft: "8px" }}
          />
        )}
        {showViewButton === "true"  && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={name} src={avatar} sx={{ width: 64, height: 64, marginRight: 2 }} />
            <VuiTypography variant="h6" color="white" fontWeight="bold" sx={{ marginRight: 2 }}>
              {name}
            </VuiTypography>
            <VuiButton onClick={handleViewProfile} color="light" size="small">
              {t('viewProfile')}
            </VuiButton>
          </div>
        )}
      </Tooltip>

      <Dialog 
        open={open} 
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#1a237e',
            color: 'white !important',
            border: '1px solid #4a148c',
          },
        }}
      >
        <DialogTitle sx={{ color: 'white !important' }}>
          {receiverRequest === currentUserId || friendRequestStatus === 'accepted' ? (
            t('manageFriendship')
          ) : (
            friendRequestStatus === 'pending' ? t('cancelFriendRequest') : t('sendFriendRequest')
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'white !important' }}>
            {receiverRequest === currentUserId || friendRequestStatus === 'accepted' ? (
              friendRequestStatus === 'accepted' ? t('confirmCancelFriendRequest') : t('confirmAcceptFriendRequest')
            ) : (
              friendRequestStatus === 'pending' ? t('confirmCancelFriendRequest') : t('confirmFriendRequest')
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleClose} color="light">
            {t('cancel')}
          </VuiButton>
          {friendRequestStatus === null && (
            <VuiButton onClick={() => handleRequest('confirm')} color="light">
              {t('confirm')}
            </VuiButton>
          )}
          {friendRequestStatus === 'pending' && (
            <>
              <VuiButton onClick={() => handleRequest('cancel')} color="error">
                {receiverRequest === currentUserId ? t('reject') : t('confirm')} 
              </VuiButton>
              {receiverRequest === currentUserId && (
                <VuiButton onClick={() => handleRequest('accept')} color="success">
                  {t('accept')}
                </VuiButton>
              )}
            </>
          )}
          {friendRequestStatus !== 'pending' && friendRequestStatus !== null &&(
            <VuiButton onClick={() => handleRequest('cancel')} color="error">
              {t('delete')}
            </VuiButton>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={
          friendRequestStatus === 'pending'
            ? t('friendRequestSent')
            : friendRequestStatus === 'accepted'
            ? t('friendRequestAccepted')
            : t('friendRequestCancelled')
        }
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#1a237e', // Fondo azul oscuro
            color: 'white', // Letra blanca
            border: '1px solid #4a148c',
          }
        }}
      />
    </>
  );
};

export default FriendRequestButton;
