import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";

const FriendCard = ({ friend }) => {
  const { t } = useTranslation();

  return (
    <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', padding: 1, maxWidth: 600 }}>
      <Link to={`/user/${friend.id}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flex: 1 }}>
        <CardContent sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px' }}>
          <Avatar alt={friend.name} src={friend.avatar} sx={{ width: 64, height: 64, marginRight: 2 }} />
          <VuiBox sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <VuiTypography variant="h6" color="white" fontWeight="bold">
              {friend.name}
            </VuiTypography>
            <VuiTypography variant="h6" color="white" fontWeight="bold">
              {friend.display_name}
            </VuiTypography>
          </VuiBox>
        </CardContent>
      </Link>
    </Card>
  );
};

FriendCard.propTypes = {
  friend: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
  }).isRequired,
};

export default FriendCard;
