import React from "react";
import { createRoot} from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthProvider } from "context/AuthContext"; // Importa el proveedor de contexto de autenticación

// Vision UI Dashboard React Context Provider
import { VisionUIControllerProvider } from "context";
import './plugins/i18n';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <AuthProvider>
      <VisionUIControllerProvider>
        <App />
      </VisionUIControllerProvider>
    </AuthProvider>
  </BrowserRouter>
);
