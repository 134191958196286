import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "utils/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import CoverLayout from "pages/authentication/components/CoverLayout";
import bgSignUp from "assets/images/signUp.jpeg";
import signUpService from "api/signup.service";
import CountrySelect from "components/CustomSelects/CountrySelect";
import { AuthContext } from "context/AuthContext";
import ReCAPTCHA from 'react-google-recaptcha';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

function SignUp() {
  const { login, user, oauthLogin } = useContext(AuthContext);
  const history = useHistory();
  const [rememberMe, setRememberMe] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const [country, setCountry] = useState("ES");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isWeb, setIsWeb] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || '';
    if (userAgent.includes('Mobile')) {
      setIsWeb(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const currentUser = localStorage.getItem('currentUser');
    if (token && currentUser) {
      history.push('/dashboard');
    }
  }, []);

  useEffect(() => {
    checkLoginStatusByCookies();
    const intervalId = setInterval(checkLoginStatusByCookies, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const checkLoginStatusByCookies = async () => {
    const token = getCookie('token');
    const email = getCookie('email');

    if (token && email) {
      try {
        const decodedEmail = decodeURIComponent(email);
        setLoading(true);
        setIsLoggedIn(true);
        deleteCookie('token');
        deleteCookie('email');
        await oauthLogin(token, decodedEmail, captchaValue, isWeb);
        history.push('/dashboard');
      } catch (error) {
        setErrorMessage(error.message || 'Unknown error');
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteCookie = (name, path = '/', domain = '.meeplefreaks.com') => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain}`;
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const GoogleIconWithText = () => (
    <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
      <FaGoogle style={{ fontSize: '24px', marginRight: '8px' }} />
      <span>Google</span>
    </div>
  );

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
  };

  const validateForm = (userData) => {
    for (const [key, value] of Object.entries(userData)) {
      if (!value) {
        return `${t(key)} ${t('isRequired')}`;
      }
      if (key === "name") {
        if (/\s/.test(value)) {
          return `${t('user')} ${t('canNotContainSpaces')}`;
        }
        if (!/^[a-zA-Z]+$/.test(value)) {
          return `${t('user')} ${t('canOnlyContainLetters')}`;
        }
      }
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const userData = {
      name: formData.get("name"),
      email: formData.get("email"),
      display_name: formData.get("display_name"),
      country: formData.get("country"),
      city: formData.get("city"),
      birthday: formData.get("birthday"),
      password: formData.get("password"),
      password_confirmation: formData.get("password_confirmation"),
    };

    const error = validateForm(userData);
    if (error) {
      setErrorMessage(error);
      return;
    }

    try {
      setLoading(true);
      if (isWeb && !captchaValue) {
        throw new Error(t("captchaError"));
      }    
      const response = await signUpService.createUser(userData);
      await login(userData["email"], userData["password"], captchaValue, isWeb);
      history.push('/dashboard');
    } catch (error) {
      if (error.message) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(t('unknowError'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const openCasdoorPopup = (provider) => {
    const url = process.env.REACT_APP_CASDOOR_OAUTH_LOGIN;
    const width = 600;
    const height = 700;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);
    window.open(url, 'Casdoor Login', `width=${width},height=${height},top=${top},left=${left}`);
  };

  if (loading) {
    return (
      <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <CoverLayout
      title={t('welcome')}
      color="white"
      description={t('description')}
      image={bgSignUp}
      premotto="MeepleFreaks:"
      motto={t('shortDescription')}
      cardContent
    >
      <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
          })}
          onSubmit={handleSubmit}
        >
          <VuiTypography
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb="24px"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            {t('registerWith')}
          </VuiTypography>
          <Stack mb="25px" justifyContent="center" alignItems="center" direction="row" spacing={2}>
            {/*
              <GradientBorder borderRadius="xl">
                <IconButton
                  onClick={() => openCasdoorPopup('casdoor')}
                  transition="all .25s ease"
                  justify="center"
                  align="center"
                  bg="rgb(19,21,54)"
                  borderradius="15px"
                  sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
                    borderRadius: borderRadius.xl,
                    padding: "25px",
                    backgroundColor: secondary.focus,
                    "&:hover": {
                      backgroundColor: rgba(secondary.focus, 0.9),
                    },
                  })}
                >
                  <Icon
                    as={FaFacebook}
                    w="30px"
                    h="30px"
                    sx={({ palette: { white } }) => ({
                      color: white.focus,
                    })}
                  />
                </IconButton>
              </GradientBorder>
              <GradientBorder borderRadius="xl">
                <IconButton
                  onClick={() => openCasdoorPopup('casdoor')}
                  transition="all .25s ease"
                  justify="center"
                  align="center"
                  bg="rgb(19,21,54)"
                  borderradius="15px"
                  sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
                    borderRadius: borderRadius.xl,
                    padding: "25px",
                    backgroundColor: secondary.focus,
                    "&:hover": {
                      backgroundColor: rgba(secondary.focus, 0.9),
                    },
                  })}
                >
                  <Icon
                    as={FaApple}
                    w="30px"
                    h="30px"
                    sx={({ palette: { white } }) => ({
                      color: white.focus,
                    })}
                  />
                </IconButton>
              </GradientBorder>
            */}
            <GradientBorder borderRadius="xl">
              <IconButton
                onClick={() => openCasdoorPopup('casdoor')}
                transition="all .25s ease"
                justify="center"
                align="center"
                bg="rgb(19,21,54)"
                borderradius="15px"
                sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
                  borderRadius: borderRadius.xl,
                  padding: "25px",
                  backgroundColor: secondary.focus,
                  "&:hover": {
                    backgroundColor: rgba(secondary.focus, 0.9),
                  },
                })}
              >
                <GoogleIconWithText />
              </IconButton>
            </GradientBorder>
          </Stack>
          <VuiTypography color="white" textAlign="center" mb="16px">
            {t('or')}
          </VuiTypography>
          <VuiBox mb="24px">
            <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg}>
              <VuiInput
                name="name"
                type="text"
                placeholder={t('user')}
                fontWeight="500"
                borderRadius="lg"
                size="large"
                sx={{
                  '& input::placeholder': {
                    color: 'white',
                    opacity: 1,
                  },
                  '&::placeholder': {
                    color: 'white',
                  },
                }}
                autocomplete="off"
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb="24px">
            <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg}>
              <VuiInput
                name="email"
                type="email"
                placeholder={t('email')}
                fontWeight="500"
                borderRadius="lg"
                size="large"
                sx={{
                  '& input::placeholder': {
                    color: 'white',
                    opacity: 1,
                  },
                  '&::placeholder': {
                    color: 'white',
                  },
                }}
                autocomplete="off"
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb="24px">
            <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg}>
              <VuiInput
                name="display_name"
                type="text"
                placeholder={t('displayName')}
                fontWeight="500"
                borderRadius="lg"
                sx={{
                  '& input::placeholder': {
                    color: 'white',
                    opacity: 1,
                  },
                  '&::placeholder': {
                    color: 'white',
                  },
                }}
                size="large"
                autocomplete="off"
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb="24px">
            <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg}>
              <CountrySelect
                name="country"
                onChange={handleCountryChange}
                value={country}
                styles={{ color: "black" }}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb="24px">
            <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg}>
              <VuiInput
                name="city"
                type="text"
                placeholder={t('city')}
                fontWeight="500"
                borderRadius="lg"
                sx={{
                  '& input::placeholder': {
                    color: 'white',
                    opacity: 1,
                  },
                  '&::placeholder': {
                    color: 'white',
                  },
                }}
                size="large"
                autocomplete="off"
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb="24px">
            <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg}>
              <VuiInput
                name="birthday"
                type="date"
                placeholder={t('birthday')}
                fontWeight="500"
                borderRadius="lg"
                // color="white"
                size="large"
                sx={{
                  '& input': {
                    color: 'white',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#001540',
                    },
                    '&:hover fieldset': {
                      borderColor: '#001540',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#001540',
                    },
                  },
                  '& .MuiInputAdornment-root': {
                    color: 'white !important',
                  },
                  '& input[type="date-local"]::-webkit-calendar-picker-indicator': {
                    filter: 'invert(1)',  // Cambia el color del icono a blanco
                  },
                  '& input::placeholder': {
                    color: 'white',
                    opacity: 1,
                  },
                  '&::placeholder': {
                    color: 'white',
                  },
                }}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb="24px">
            <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg}>
              <VuiInput
                name="password"
                type="password"
                placeholder={t('password')}
                fontWeight="500"
                borderRadius="lg"
                sx={{
                  '& input::placeholder': {
                    color: 'white',
                    opacity: 1,
                  },
                  '&::placeholder': {
                    color: 'white',
                  },
                }}
                size="large"
                autocomplete="new-password"
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb="24px">
            <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg}>
              <VuiInput
                name="password_confirmation"
                type="password"
                placeholder={t('confirmPassword')}
                fontWeight="500"
                borderRadius="lg"
                sx={{
                  '&::placeholder': {
                    color: 'white',
                  },
                  '& input::placeholder': {
                    color: 'white',
                    opacity: 1,
                  },
                }}
                size="large"
                autocomplete="new-password"
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox display="flex" alignItems="center">
            <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
            <VuiTypography
              variant="button"
              fontWeight="regular"
              color="white"
              onClick={handleSetRememberMe}
              sx={{
                ml: 1,
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              {t('rememberMe')}
            </VuiTypography>
          </VuiBox>
          {errorMessage && (
            <VuiBox mt="24px">
              <VuiTypography color="error" textAlign="center">
                {errorMessage}
              </VuiTypography>
            </VuiBox>
          )}
          {isWeb && (
            <VuiBox mt="24px" mb="24px">
              <ReCAPTCHA
                sitekey="6LeLsSkqAAAAAKYxsxHgrhCLT01xKoOjdITSOtAA"
                onChange={handleCaptchaChange}
              />
            </VuiBox>
          )}
          <VuiBox mt="24px" mb="24px">
            <VuiButton color="info" size="large" type="submit" fullWidth>
              {t('signUp')}
            </VuiButton>
          </VuiBox>
          <VuiTypography
            color="white"
            variant="button"
            textAlign="center"
            fontWeight="regular"
          >
            {t('alreadyHaveAccount')}{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="info"
              fontWeight="bold"
            >
              {t('signIn')}
            </VuiTypography>
          </VuiTypography>
          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              {t("forgotPasswordMessage")}{" "}
              <VuiTypography
                component={Link}
                to="/authentication/forgot-password"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("forgotPassword")}
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              {t("termsAndConditionsMessage")}{" "}
              <VuiTypography
                component={Link}
                to="/terms-and-conditions"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("termsAndConditions")}
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              {t("termsAndConditionsMessage")}{" "}
              <VuiTypography
                component={Link}
                to="/policy-and-privacy"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("policyAndPrivacy")}
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </CoverLayout>
  );
}

export default SignUp;
