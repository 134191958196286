import React, { useState } from "react";
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import VuiBadge from "components/VuiBadge";
import { Link } from "react-router-dom";
import ShareButton from 'components/CustomButtons/ShareButton'; 
import dataService from 'api/data.service';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

function UpcomingEventCard({ image, label, title, description, actions = [], options = [], visible, event_type, games, event_id, event_date, players, owner, owner_id, event_bookmarked, languages }) {
  const { t } = useTranslation();
  const [bookmarked, setBookmarked] = useState(event_bookmarked); // Estado para gestionar el marcado

  const handleBookmark = async () => {
    try {
      const action = bookmarked ? 'unbookmark' : 'bookmark';
      await dataService.bookmarkEvent(event_id, action);
      setBookmarked(!bookmarked);
    } catch (error) {
      console.error(error.message);
    }
  };

  const squareSize = 300;
  const thumbnailSize = squareSize / Math.ceil(Math.sqrt(games.length));
  const renderGameThumbnails = games.map((game, index) => (
    <Link key={index} to={`/game/${game.id}`} style={{ textDecoration: "none" }} title={game.title}>
      <VuiAvatar
        src={game.thumbnail}
        alt={game.name}
        title={game.name}
        sx={{
          width: `${thumbnailSize}px`,
          height: `${thumbnailSize}px`,
          borderRadius: "8px",
          objectFit: "cover",
        }}
      />
    </Link>
  ));

  const formatDate = (date) => {
    const eventDate = new Date(date);
    const year = eventDate.getFullYear();
    const month = String(eventDate.getMonth() + 1).padStart(2, '0');
    const day = String(eventDate.getDate()).padStart(2, '0');
    const hours = String(eventDate.getHours()).padStart(2, '0');
    const minutes = String(eventDate.getMinutes()).padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  const EventTypeTag = ({ eventType }) => {
    const getTagColor = (eventType) => {
      switch (eventType) {
        case 'public':
          return 'info';
        case 'private':
          return 'warning';
        default:
          return 'text';
      }
    };

    return (
      <VuiBadge
        badgeContent={eventType === 'public' ? t('openEvent') : t('privateEvent')}
        size="xs"
        color={getTagColor(eventType)}
        container
        ml={1}
      />
    );
  };

  const VisibilityTag = ({ visible }) => {
    return (
      <VuiBadge
        badgeContent={visible ? t('visibleEvent') : t('invisibleEvent')}
        size="xs"
        color={visible ? 'success' : 'error'}
        container
        ml={1}
      />
    );
  };

  const LanguagesTag = ({ languages }) => {

    return (
      <>
        {languages.map((language, index) => (
          <VuiBadge
            key={index}
            badgeContent={t(`languages.${language}`)}
            size="xs"
            color="primary"
            container
            sx={{ marginRight: '4px' }}
          />
        ))}
      </>
    );
  };

  const renderOptions = options.map(({ image: media, name }) => (
    <VuiAvatar
      key={name}
      src={media}
      alt={name}
      size="xs"
      sx={({ borders: { borderWidth }, palette: { dark }, functions: { rgba } }) => ({
        border: `${borderWidth[2]} solid ${rgba(dark.focus, 0.5)}`,
        cursor: "pointer",
        position: "relative",
        ml: -1.25,
        "&:hover, &:focus": {
          zIndex: "10",
        },
      })}
    />
  ));

  return (
    <VuiBox>
      <VuiBox
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          boxShadow: "none",
          overflow: "visible",
          mb: 2, // Margin bottom for separation
        }}
      >
        <VuiBox
          sx={{
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            mb: { xs: 2, lg: 0 },
          }}
        >
          <VuiTypography variant="h6" color="text" fontWeight="medium">
            {formatDate(event_date)}
          </VuiTypography>
          <VuiTypography variant="h3" color="text" fontWeight="medium">
            {title}
          </VuiTypography>
          <VuiBox>
            <EventTypeTag eventType={event_type} />{" "}
            <VisibilityTag visible={visible} />{" "}
            <LanguagesTag languages={languages} />
          </VuiBox>
          <VuiBox mb={1}>
            <VuiTypography variant="h5" color="white">
              {label}
            </VuiTypography>
          </VuiBox>
          <VuiBox mb={3} lineHeight={0} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              <VuiTypography
                variant="h6"
                fontWeight="regular"
                color="text"
                dangerouslySetInnerHTML={{ __html: description }}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
              />
            </VuiTypography>
          </VuiBox>
          <VuiBox>
            <VuiTypography variant="h6" color="text" fontWeight="medium">
              {t('willAttend', { count: players })} · {t('organizer')} <Link to={`/user/${owner_id}`} style={{ textDecoration: 'none', color: 'white' }}>{owner}</Link>
            </VuiTypography>
          </VuiBox>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <VuiBox display="flex">
              {actions.map((action, index) => (
                <VuiButton
                  key={index}
                  component={action.type === "internal" ? Link : "a"}
                  to={action.type === "internal" ? action.route : null}
                  href={action.type === "external" ? action.route : null}
                  target={action.type === "external" ? "_blank" : null}
                  rel={action.type === "external" ? "noreferrer" : null}
                  variant="outlined"
                  size="small"
                  color={action.color}
                  placeholder={action.label}
                  onClick={action.onClick}
                  sx={{ ml: index === 0 ? 0 : 2 }}
                >
                  {action.label}
                  {action.icon}
                </VuiButton>
              ))}
              <VuiButton
                size="small"
                variant="contained"
                color={bookmarked ? "warning" : "light"}
                onClick={handleBookmark}
                startIcon={bookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                sx={{ ml: actions.length > 0 ? 2 : 0 }}
              >
                {bookmarked ? t('unbookmark') : t('bookmark')}
              </VuiButton>
              <VuiBox sx={{ ml: 2 }}>
                <ShareButton
                  title={title}
                  text={t('lookAtThisEvent')}
                  url={`${process.env.REACT_APP_DEFAULT_DOMAIN}/event/${event_id}`}
                />
              </VuiBox>
            </VuiBox>
            <VuiBox display="flex">{renderOptions}</VuiBox>
          </VuiBox>
        </VuiBox>
        <VuiBox
          sx={{
            flex: { xs: '0 0 100%', lg: '0 0 250px' },
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "left",
            mt: { xs: 2, lg: 0 },
            ml: { lg: 2 },
          }}
        >
          {image && image.length > 0 ? (
            <VuiBox
              component="img"
              src={image}
              borderRadius="15px"
              sx={{ width: "100%", height: "auto" }}
            />
          ) : (
            <VuiBox
              sx={{
                width: `${squareSize}px`,
                height: `${squareSize}px`,
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {renderGameThumbnails}
            </VuiBox>
          )}
        </VuiBox>
      </VuiBox>
      <hr />
    </VuiBox>  );
}

// Validación de tipos de propiedades
UpcomingEventCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]),
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "white",
        "text",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "white",
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  event_type: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  games: PropTypes.arrayOf(PropTypes.object).isRequired,
  players: PropTypes.number.isRequired,
  owner: PropTypes.string.isRequired,
  owner_id: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
};

export default UpcomingEventCard;
