import React from 'react';
import { SvgIcon } from '@mui/material';

const GameIcon = (props) => {
  return (
    <SvgIcon component="svg" {...props}>
      {/* Define aquí el contenido SVG de tu icono */}
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 16h-2v-2h2v2zm4 0h-2v-2h2v2zm0-4h-2v-2h2v2zm-4 0h-2v-2h2v2zm-4-6h2v2H7V8zm4 2h2v2h-2v-2zm4-2h2v2h-2V8zm0 4h2v2h-2v-2z"/>
    </SvgIcon>
  );
};

export default GameIcon;
