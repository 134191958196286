import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dataService from 'api/data.service';
import DashboardLayout from 'utils/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'utils/Navbars/DashboardNavbar';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import VuiInput from 'components/VuiInput';
import VuiButton from 'components/VuiButton';
import VuiAvatar from "components/VuiAvatar";
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ScoresEventCard from "utils/Cards/EventCard/ScoresEventCard";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info"; 

import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const EventScores = () => {
  const { id } = useParams();
  const { t } = useTranslation();  // Para traducciones
  const [participants, setParticipants] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [profile, setProfile] = useState(null);
  const [eventData, setEventData] = useState({
    name: '',
    event_date: '',
    description: '',
    min_players: '',
    max_players: '',
    percentage: '',
    event_type: 'private',
    languages: [],
    address: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'ES',
    visible: false,
    allow_guest_notes: false,
    games: [],
  });

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const data = await dataService.getEventById(id);
        const profile_data = await dataService.getSessionProfile();
        if (profile_data.resource.id !== data.resource.owner_profile.id) {
          history.push(`/event/${data.resource.id}`);
        }
        setProfile(profile_data.resource);
        setEventData(data.resource);
        setParticipants(data.resource.participant_scores);
        setGames(data.resource.games);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [id]);

  const handleChange = (index, field, value) => {
    const updatedParticipants = [...participants];
    updatedParticipants[index][field] = value;
    setParticipants(updatedParticipants);
  };

  const handleRatingChange = (index, newRating) => {
    const updatedParticipants = [...participants];
    updatedParticipants[index]['owner_rating'] = newRating;
    setParticipants(updatedParticipants);
  };

  const handleSubmit = async () => {
    try {
      setSaving(true);
      setLoading(true);
      await dataService.updateScores(id, participants);
    } catch (err) {
      setError(error.message);
    } finally {
      setSaving(false);
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const eventImage = (games) => {
    if (eventData.image) {
      return eventData.image;
    }
    else if (games.length === 1){
      return games[0].thumbnail
    }
    else {
      return ""
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox sx={{ padding: 3 }}>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid item xs={12} md={12} xl={12}>
            <ScoresEventCard
              image={eventImage(games)}
              label={eventData.name}
              title={eventData.name}
              description={games.map(game => game.title).join(', ')}
              owner={eventData.owner_profile}
              games={games}
              event_id={eventData.id}
              event_status={eventData.status}
              actions={
                [
                  {
                    type: "internal",
                    route: `/event/${eventData.id}`,
                    color: "light",
                    label: t('see'),
                    icon: <InfoIcon />,
                  }
                ]
              }
              options={[]}
            />
          </Grid>
        </Grid>
        <VuiBox my={2}>
          <hr />
        </VuiBox>
        {error && (
          <VuiBox>
            <VuiTypography color="error">{t('errorOccurred')}: {error}</VuiTypography>
          </VuiBox>
        )}
        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" indicatorColor="success" sx={{ background: 'transparent' }}>
          {participants.map((participant, index) => (
            <Tab 
              label={participant.profile.display_name} 
              key={index} 
              sx={{ color: 'white', fontSize: '12px' }} 
            />
          ))}
        </Tabs>
        {participants.map((participant, index) => (
          <VuiBox key={participant.profile.id} role="tabpanel" hidden={selectedTab !== index}>
            {selectedTab === index && (
              <VuiBox p={3}>
                <VuiBox display="flex" alignItems="center" mb={2}>
                  <VuiAvatar src={participant.profile.avatar} alt={participant.profile.display_name} size="xs" />
                  <VuiTypography variant="h6" color="light" sx={{ color: 'white', fontSize: '18px' }}>
                    {participant.profile.display_name} ({participant.profile.name})
                  </VuiTypography>
                </VuiBox>
                <VuiBox display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                  <VuiBox >
                    <VuiTypography variant="body1" mr={1} color="light" sx={{ color: 'white', fontSize: '14px' }}>
                      {t('score')}:
                    </VuiTypography>
                    <VuiInput
                      type="number"
                      value={participant.score || ''}
                      onChange={(e) => handleChange(index, 'score', parseInt(e.target.value, 10))}
                      sx={{ width: '80px', fontSize: '14px' }}
                    />
                  </VuiBox>
                  <VuiBox ml={3}>
                    <VuiTypography variant="body1" mr={1} color="light" sx={{ color: 'white', fontSize: '14px' }}>
                      {t('podium')}:
                    </VuiTypography>
                    <VuiInput
                      type="number"
                      value={participant.podium_position || ''}
                      onChange={(e) => handleChange(index, 'podium_position', parseInt(e.target.value, 10))}
                      sx={{ width: '80px', fontSize: '14px' }}
                    />
                  </VuiBox>
                  {profile.id !== participant.profile.id && (
                    <VuiBox ml={3}>
                      <VuiTypography variant="body1" mr={1} color="light" sx={{ color: 'white', fontSize: '14px' }}>
                        {t('ownerRating')}:
                      </VuiTypography>
                      <VuiBox display="flex" alignItems="center">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <VuiBox
                            key={star}
                            onClick={() => handleRatingChange(index, star)}
                            sx={{ cursor: 'pointer', color: participant.owner_rating >= star ? '#ffc107' : '#e4e5e9' }}
                          >
                            {participant.owner_rating >= star ? <StarIcon /> : <StarBorderIcon />}
                          </VuiBox>
                        ))}
                      </VuiBox>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox mb={2}>
                  <VuiTypography variant="body1" mb={1} color="light" sx={{ color: 'white', fontSize: '14px' }}>
                    {t('generalDescription')}:
                  </VuiTypography>
                  <VuiInput
                    type="text"
                    multiline
                    rows={4}
                    value={participant.description || ''}
                    onChange={(e) => handleChange(index, 'description', e.target.value)}
                    sx={{ width: '100%', fontSize: '14px', color: 'white' }}
                  />
                </VuiBox>
              </VuiBox>
            )}
          </VuiBox>
        ))}
        <VuiButton variant="contained" color="success" onClick={handleSubmit} disabled={saving}>
          {saving ? <CircularProgress size={24} /> : t('saveChanges')}
        </VuiButton>
      </VuiBox>
      {loading && (
        <VuiBox
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }}
        >
          <CircularProgress color="inherit" />
        </VuiBox>
      )}
    </DashboardLayout>
  );
};

export default EventScores;
