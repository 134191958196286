import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import dataService from "api/data.service";

export default function Data({ userId }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await dataService.getUserLastEvents(userId);
        if (data.resources.length === 0) {
          setRows([]);
        } else {
          const formattedRows = data.resources.map((event) => ({
           [t('games')]: (
              <VuiBox display="flex">
                {event.games.slice(0, 5).map((game, index) => (
                  <VuiBox key={index} display="inline-flex" alignItems="center" mr={2}>
                    <Link to={`/game/${game.id}`} style={{ textDecoration: "none" }} title={game.title} >
                      {renderGameIcon(game.thumbnail)}
                      {event.games.length === 1 && (
                        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                          {game.title}
                        </VuiTypography>
                      )}
                    </Link>
                  </VuiBox>
                ))}
              </VuiBox>
            ),
            [t('players')]: (
              <VuiBox display="flex" py={1}>
                {avatars(event.players)}
              </VuiBox>
            ),
            [t('date')]: (
              <VuiTypography variant="button" color="white" fontWeight="bold">
                {formatDate(event.event_date)}
              </VuiTypography>
            ),
            [t('completion')]: (
              <VuiBox width="8rem" textAlign="left">
                <VuiTypography color="white" variant="button" fontWeight="bold">
                  {event.percentage}%
                </VuiTypography>
                <VuiProgress
                  value={event.percentage}
                  color="info"
                  label={false}
                  sx={{ background: "#2D2E5F" }}
                />
              </VuiBox>
            ),
          }));
          setRows(formattedRows);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [userId]);

  const avatars = (players) =>
    players.map((player, index) => (
      <Tooltip key={index} title={player.display_name} placement="bottom">
        <VuiAvatar
          src={player.avatar}
          alt={player.display_name}
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",
            "&:not(:first-of-type)": {
              ml: -1.25,
            },
            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const renderGameIcon = (thumbnail) => (
    <img
      src={thumbnail}
      alt="Game Thumbnail"
      style={{
       width: "20px",
       height: "20px",
       borderRadius: "50%",
       overflow: "hidden"
      }}
    />
  );

  const formatDate = (date) => {
    const eventDate = new Date(date);
    const year = eventDate.getFullYear();
    const month = String(eventDate.getMonth() + 1).padStart(2, '0');
    const day = String(eventDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  if (loading) {
    return (
      <VuiTypography variant="button" color="white" fontWeight="bold">
        {t('loading')}}
      </VuiTypography>
    );
  }

  if (rows.length === 0) {
    return (
      <VuiTypography variant="button" color="white" fontWeight="bold">
        {t('not_events')}
      </VuiTypography>
    );
  }

  return { columns: [
     { name: t('games'), align: "left" },
     { name: t('players'), align: "left" },
     { name: t('date'), align: "center" },
     { name: t('completion'), align: "center" },
    ],

    rows: rows,
  };
}
