import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import loginService from "../api/login.service"; // Importa el servicio de inicio de sesión

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUser(decoded);
        localStorage.setItem('currentUser', JSON.stringify(decoded));
      } catch (error) {
        //console.error("Error decoding token:", error);
        setUser(null);
      }
    }
  }, []);

  const login = async (email, password, captchaValue, isWeb) => {
    try {
      const response = await loginService.signIn(email, password, captchaValue, isWeb);
      if (response && response.token) {
        const { token } = response;
        localStorage.setItem("token", token);
        const decoded = jwtDecode(token);
        setUser(decoded);
        localStorage.setItem('currentUser', JSON.stringify(decoded));
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Login error:", error);
      throw new Error("Invalid email or password and check ReCaptcha");
    }
  };

  const oauthLogin = async (oauthToken, email, captchaValue, isWeb) => {
    try {
      const response = await loginService.emailVerification(email, captchaValue, isWeb);
      if (response && jwtDecode(response.token)["user_id"] === jwtDecode(oauthToken)["user_id"]) {
        const { token } = response;
        localStorage.setItem("token", token);
        const decoded = jwtDecode(token);
        setUser(decoded);
        localStorage.setItem('currentUser', JSON.stringify(decoded));
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Login error:", error);
      throw new Error("Invalid email or password and check ReCaptcha");
    }
  };

  const logout = async () => {
    try {
      // await loginService.signOut();
      localStorage.removeItem("token");
      localStorage.removeItem('currentUser');
      setUser(null);
    } catch (error) {
      //console.error("Logout error:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, oauthLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
