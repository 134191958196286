import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch, useLocation, Link } from 'react-router-dom'

import dataService from 'api/data.service';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import DashboardLayout from "utils/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utils/Navbars/DashboardNavbar";
import Footer from "utils/Footer";
// Overview page components
import DefaultGameCard from "utils/Cards/GameCard/DefaultGameCard";
import GameInfoCard from "utils/Cards/InfoCards/GameInfoCard";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon } from '@fortawesome/free-brands-svg-icons';

import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info"; 
import { ReactComponent as NotFound } from "assets/svgs/sad.svg";
import { Typography, Grid } from '@mui/material';

function Overview() {
  const { t, i18n } = useTranslation();
  
  const searchParams = new URLSearchParams(location.search);
  const filter = searchParams.get('filter');

  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchGames = useCallback(async (page, filter) => {
    try {
      const data = await dataService.collectionGames(page, 10, filter);
      setGames(prevGames => [...prevGames, ...data.resources]);
      setHasMore(data.resources.length > 0);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchGames(page, filter);
  }, [page, filter, fetchGames]);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight && hasMore && !loading) {
      setPage(prevPage => prevPage + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  if (loading && page === 1) {
    return <div>{t('loading')}</div>;
  }

  if (error) {
    return <div>{t('error', { error })}</div>;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={5} mb={3} >
        {filter && (
          <div style={{ marginBottom: '10px', padding: '10px', color: 'white' }}>
            {t('filteringBy')} "{filter}"
          </div>
        )}
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          {games.length > 0 ? (
            games.map((game, index) => (
              <React.Fragment key={index}>
                <Grid item xs={6} md={4} xl={3}>
                  <Link to={`/game/${game.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <GameInfoCard
                      image={game.image}
                      title={game.title}
                      description=""
                      info={{
                        [t('ageRecommendation')]: game.age_recommendation,
                        [t('players')]: `${game.min_players}/${game.max_players}`,
                        [t('time')]: `${game.min_playtime}/${game.max_playtime} min.`,
                        [t('categoriesLabel')]: game.categories.map(category => t(`categories.${category.name}`)).join(', ') || 'N/A',
                        [t('rank')]: game.rank || 'N/A',
                        [t('popularity')]: ( <span>
                              {game.likes_count}
                              <FavoriteIcon style={{ color: 'red', marginRight: '5px' }} />
                              {game.desired_count}
                              <BookmarkIcon style={{ color: 'blue', marginRight: '5px' }} />
                              {game.collected_count}
                              <CheckCircleIcon style={{ color: 'green', marginRight: '5px' }} />
                        </span>
                        ),
                      }}
                      social={[]}
                      sellers={[]}
                    />
                  </Link>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <VuiBox mt={12} textAlign="center" xs={12} md={12} xl={12} color="light">
              <NotFound style={{ width: '55%', height: '55%' }} />
              <Typography variant="h3" sx={{ color: 'white !important' }}>{t('noGameData')}</Typography>
            </VuiBox>
          )}
        </Grid>
      </VuiBox>
      {loading && <div>...</div>}
      <Footer />
    </DashboardLayout>
  );
  function getGameDescription(game) {
    const currentLanguage = i18n.language;
    if (!game.translations || game.translations.length === 0) {
      return game.description;
    }
    const translation = game.translations.find(trans => trans.language === currentLanguage);
    return translation ? translation.translated_description : game.description;
  }
}
export default Overview;
