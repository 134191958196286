import React from "react";
import { Card, Icon, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import gif from "assets/images/welcome-profile.png";
import { useTranslation } from "react-i18next";
import { ReactComponent as DonutsLove } from 'assets/svgs/donutsLove.svg';

const WelcomeMark = () => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        height: "340px",
        py: "32px",
        backgroundImage: `url(${gif})`,
        backgroundSize: "cover",
        backgroundPosition: "50%",
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={7} md={8} lg={9}>
          <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <VuiBox>
              <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
                {t('welcomeCommunity')}
              </VuiTypography>
              <VuiTypography color="white" variant="h3" fontWeight="bold" mb="18px">
                {t('explorePlayConnect')}
              </VuiTypography>
              <VuiTypography color="white" variant="h5" fontWeight="regular" mb="auto">
                {t('createJoinEvents')}
              </VuiTypography>
            </VuiBox>
            <VuiTypography
              component="a"
              href="upcoming-games"
              variant="button"
              color="white"
              fontWeight="regular"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translate(2px, -0.5px)`,
                  transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                },

                "&:hover .material-icons-round, &:focus  .material-icons-round": {
                  transform: `translate(6px, -0.5px)`,
                },
              }}
            >
              {t('discoverEvents')}
              <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_forward</Icon>
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item xs={5} md={4} lg={3}>
          <DonutsLove style={{ width: '100%', height: 'auto' }} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default WelcomeMark;
