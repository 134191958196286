import React from "react";
import PropTypes from "prop-types";
import logoImage from "assets/images/logo.png";

function SimmmpleLogo({ size="16px" }) {
  return (
    <img
      src={logoImage}
      alt="Simmmple Logo"
      width={size}
      height={size}
    />
  );
}

// Typechecking props for the SimmmpleLogo
SimmmpleLogo.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SimmmpleLogo;
