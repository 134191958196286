import React, { useEffect, useState } from 'react';
import { Card, Stack, CircularProgress, Box } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';
import linearGradient from 'assets/theme/functions/linearGradient';
import dataService from 'api/data.service';
import { FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function GameRating({ userId }) {
  const { t } = useTranslation();
  const { info, gradients } = colors;
  const { cardContent } = gradients;

  const [userRating, setUserRating] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserRating = async () => {
      try {
        const data = await dataService.getUserRating(userId);
        setUserRating(data.resource);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserRating();
  }, [userId]);

  return (
    <Card
      sx={{
        height: '100%',
        background: linearGradient(gradients.cardDark.main, gradients.cardDark.state, gradients.cardDark.deg)
      }}>
      <VuiBox sx={{ width: '100%' }}>
        <VuiBox
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{ width: '100%' }}
          mb='40px'>
          <VuiTypography variant='lg' color='white' mr='auto' fontWeight='bold'>
            {t('gameRating')}
          </VuiTypography>
        </VuiBox>
        <VuiBox
          display='flex'
          sx={({ breakpoints }) => ({
            [breakpoints.up('xs')]: {
              flexDirection: 'column',
              gap: '16px',
              justifyContent: 'center',
              alignItems: 'center'
            },
            [breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }
          })}>
          <Stack
            direction='column'
            spacing='20px'
            width='500px'
            maxWidth='50%'
            sx={({ breakpoints }) => ({
              mr: 'auto',
              [breakpoints.only('md')]: {
                mr: '75px'
              },
              [breakpoints.only('xl')]: {
                width: '500px',
                maxWidth: '40%'
              }
            })}>
            <VuiBox
              display='flex'
              width='220px'
              p='20px 22px'
              flexDirection='column'
              sx={({ breakpoints }) => ({
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: '20px',
                [breakpoints.up('xl')]: {
                  maxWidth: '110px !important'
                },
                [breakpoints.up('xxl')]: {
                  minWidth: '180px',
                  maxWidth: '100% !important'
                }
              })}>
              <VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
                {t('guest')}
              </VuiTypography>
              <VuiTypography color='white' variant='lg' fontWeight='bold'>
                {userRating && userRating.participated_events_count} {t('games')}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              display='flex'
              width='220px'
              p='20px 22px'
              flexDirection='column'
              sx={({ breakpoints }) => ({
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: '20px',
                [breakpoints.up('xl')]: {
                  maxWidth: '110px !important'
                },
                [breakpoints.up('xxl')]: {
                  minWidth: '180px',
                  maxWidth: '100% !important'
                }
              })}>
              <VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
                {t('host')}
              </VuiTypography>
              <VuiTypography color='white' variant='lg' fontWeight='bold'>
                {userRating && userRating.events_owner_count} {t('games')}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              display='flex'
              width='220px'
              p='20px 22px'
              flexDirection='column'
              sx={({ breakpoints }) => ({
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: '20px',
                [breakpoints.up('xl')]: {
                  maxWidth: '110px !important'
                },
                [breakpoints.up('xxl')]: {
                  minWidth: '180px',
                  maxWidth: '100% !important'
                }
              })}>
              <VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
                {t('playedHours')}
              </VuiTypography>
              <VuiTypography color='white' variant='lg' fontWeight='bold'>
                {userRating && userRating.total_played_hours}
              </VuiTypography>
            </VuiBox>
          </Stack>
          <VuiBox sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              variant='determinate'
              value={userRating && userRating.rating * 20}
              size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
              color='success'
            />
            <VuiBox
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <VuiBox display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                <VuiTypography
                  color='white'
                  variant='d5'
                  fontWeight='bold'
                  mb='4px'
                  sx={({ breakpoints }) => ({
                    [breakpoints.only('xl')]: {
                      fontSize: '32px'
                    }
                  })}>
                  {userRating && userRating.rating * 2}
                </VuiTypography>
                <VuiTypography color='text' variant='button'>
                  {t('totalScore')}
                </VuiTypography>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </VuiBox>
        <VuiBox mt={3} display="flex" flexDirection="column" alignItems="center">
          {loading ? (
            <CircularProgress color="secondary" />
          ) : error ? (
            <VuiTypography color="error">{error}</VuiTypography>
          ) : (
            <Box display="flex" alignItems="center">
              <VuiTypography color="text" variant="button" mr={1}>
                {t('userRating')}
              </VuiTypography>
              <Box display="flex" alignItems="center">
                {[...Array(5)].map((star, index) => (
                  <FaStar
                    key={index}
                    color={index < (userRating && userRating.rating) ? "#ffc107" : "#e4e5e9"}
                  />
                ))}
              </Box>
            </Box>
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default GameRating;
