import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dataService from 'api/data.service';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import DashboardLayout from "utils/LayoutContainers/DashboardLayout";
import Footer from "utils/Footer";
import UserInfoCard from "utils/Cards/InfoCards/UserInfoCard";
import UserHeader from "pages/user/components/UserHeader";

// React icons
import { IoGameController, IoStatsChart, IoPeople } from "react-icons/io5";
import MiniStatisticsCard from "utils/Cards/StatisticsCards/MiniStatisticsCard";
import LastEvents from "pages/user/components/LastEvents";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from '@mui/icons-material/YouTube';

import { Card, Stack, CircularProgress, Box } from '@mui/material';
import { FaStar } from 'react-icons/fa';
import { AiFillHeart } from "react-icons/ai";
import { ReactComponent as Friendship } from "assets/svgs/friendship.svg";
import { Typography, Grid } from '@mui/material';

function Overview() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [rating, setRating] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);
  const [friendStatus, setFriendStatus] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const profile_data = await dataService.getSessionProfile();
        setProfile(profile_data.resource);
        const data = await dataService.getUserById(id);
        setUser(data.resource.get_profile);
        setRating(data.resource.get_rating);
        const friendShip = await dataService.getFriendRequestStatus(id);
        if (friendShip.resource !== null) {
          setFriendStatus(friendShip.resource.status);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  // Icon mapping for platforms
  const platformIcons = {
    facebook: <FacebookIcon />,
    instagram: <InstagramIcon />,
    X: <TwitterIcon />,
    youtube: <YouTubeIcon />,
  };

  // Function to get social links
  const getSocialLinks = (socialLinks) => {
    return socialLinks.map(link => {
      return {
        link: link.url,
        icon: platformIcons[link.platform] || null,
        color: "white",
      };
    });
  };

  if (loading) {
    return <div>{t('loading')}...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <DashboardLayout>
      <UserHeader
        name={user.name}
        display_name={user.display_name}
        avatar={user.avatar}
        userId={user.id}
        currentUserId={profile.id}
      />
      {friendStatus === "accepted" || profile.id == user.id ? (
        <VuiBox mt={5} mb={3}>
            <VuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={3}>
                  <MiniStatisticsCard
                    title={{ text: t('gamesPlayed') }}
                    count={user.events_count}
                    icon={{ color: "info", component: <IoStatsChart size="22px" color="white" /> }}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <MiniStatisticsCard
                    title={{ text: t('totalScore') }}
                    count={
                      <Box display="flex" alignItems="center">
                        {[...Array(5)].map((star, index) => (
                          <FaStar
                            key={index}
                            color={index < (rating && rating.rating) ? "#ffc107" : "#e4e5e9"}
                          />
                        ))}
                      </Box>
                    }
                    icon={{ color: "info", component: <FaStar size="22px" color="white" /> }}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <MiniStatisticsCard
                    title={{ text: t('totalGames'), fontWeight: "regular" }}
                    count={user.collection_count}
                    icon={{ color: "info", component: <IoGameController size="22px" color="white" /> }}
                    href={`/user-collection/${id}`}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <MiniStatisticsCard
                    title={{ text: t('routes.wishlist') }}
                    count={user.desired_count}
                    icon={{ color: "info", component: <AiFillHeart size="22px" color="white" /> }}
                    href={`/user-wishlist/${id}`}
                  />
                </Grid>
              </Grid>
            </VuiBox>
            <Grid
              container
              spacing={3}
              sx={({ breakpoints }) => ({
                [breakpoints.only("xl")]: {
                  gridTemplateColumns: "repeat(2, 1fr)",
                },
              })}
            >
              <Grid
                item
                xs={12}
                xl={3}
                xxl={3}
                sx={({ breakpoints }) => ({
                  [breakpoints.only("xl")]: {
                    gridArea: "1 / 2 / 2 / 3",
                  },
                })}
              >
                <UserInfoCard
                  title={t('userInformation')}
                  description={user.about_me}
                  info={{
                    [t('fullName')]: user.display_name,
                    [t('country')]: user.country,
                    [t('city')]: user.city,
                    [t('birthday')]: user.birthday,
                  }}
                  social={getSocialLinks(user.social)}
                  style={{
                    border: "2px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                    backgroundColor: "#f9f9f9"
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6} xl={9}>
                <LastEvents
                  userId={user.id}
                  eventsThisMonth={user.events_this_mont}
                />
              </Grid>
            </Grid>
        </VuiBox>
      ) : (
        <VuiBox mt={12} textAlign="center" xs={12} md={12} xl={12} color="light">
          <Typography variant="h3" sx={{ color: 'white !important' }}>{t('notCurrentFriends')}</Typography>
          <Friendship style={{ width: '35%', height: '35%' }} />
        </VuiBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
