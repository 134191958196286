import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { CgAdd } from "react-icons/cg";
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import VuiInput from 'components/VuiInput';
import Breadcrumbs from 'utils/Breadcrumbs';
import NotificationItem from 'utils/Items/NotificationItem';
import { getTranslatedRoutes } from 'routes';
import { useVisionUIController, setTransparentNavbar, setMiniSidenav } from 'context';
import { navbar, navbarContainer, navbarRow, navbarIconButton, navbarMobileMenu } from "utils/Navbars/DashboardNavbar/styles";
import logoSpotify from 'assets/images/small-logos/logo-spotify.svg';
import logoNoThumbnail from 'assets/images/logo.png';
import VuiBadge from "components/VuiBadge";
import dataService from 'api/data.service';
import VuiButton from "components/VuiButton";

function DashboardNavbar({ absolute=false, light=false, isMini=false }) {
  const { t } = useTranslation();
  const routes = getTranslatedRoutes(t);
  const [navbarType, setNavbarType] = useState();
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [translatedTitle, setTranslatedTitle] = useState('');
  const [notifications, setNotifications] = useState([]);
  const route = useLocation().pathname.split('/').slice(1);
  const history = useHistory();

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener('scroll', handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    if (route.length > 0 && route[0] === 'game') {
      const gameId = route[1];
      getTranslatedTitle(gameId, 'game').then(setTranslatedTitle);
    } else if (route.length > 0 && route[0] === 'event') {
      const eventId = route[1];
      getTranslatedTitle(eventId, 'event').then(setTranslatedTitle);
    } else if (route.length > 0 && (route[0] === 'user' || route[0] === 'user-collection' || route[0] === 'user-wishlist')) {
      const userId = route[1];
      getTranslatedTitle(userId, 'user').then(setTranslatedTitle);
    } else if (route.length > 0 && route[0] === 'edit-event') {
      const eventId = route[1];
      getTranslatedTitle(eventId, 'event').then(setTranslatedTitle);
    } else if (route.length > 0 && route[0] === 'event-scores') {
      const eventId = route[1];
      getTranslatedTitle(eventId, 'event').then(setTranslatedTitle);
    } else {
      setTranslatedTitle('');
    }
  }, [route]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const notificationsData = await dataService.getNotifications();
        setNotifications(notificationsData.resources);
      } catch (error) {
        setNotifications([]);
      }
    };

    fetchNotifications();
  }, []);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => {
    history.push('/configurations');
  };
  const handleOpenMenu = event => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleSearchChange = async event => {
    const { value } = event.target;
    setSearch(value);
    if (value.length > 2) {
      try {
        const results = await dataService.discoverGames(1, 5, value);
        setSearchResults(results.resources); // Asegúrate de que 'resources' es la clave correcta para los datos de juegos
      } catch (error) {
        console.error('Error fetching search results:', error);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchSelect = game => {
    setSearch('');
    setSearchResults([]);
    history.push(`/game/${game.id}`);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      history.push(`/games?filter=${search}`);
      history.go();
    }
  };

  const handleCreateEvent = () => {
    history.push('/create-event');
  };

  const renderBreadcrumbs = () => {
    const lastRoute = route[route.length - 1];
    const title = translatedTitle || t(`routes.${lastRoute}`, { defaultValue: `${lastRoute}` });

    return (
      <Breadcrumbs
        icon="home"
        title={title}
        route={route}
        light={light}
      />
    );
  };

  const formatDate = (date) => {
    const eventDate = new Date(date);
    const now = new Date();
    const diffInMs = now - eventDate; // Diferencia en milisegundos
    const diffInMinutes = diffInMs / (1000 * 60); // Diferencia en minutos
    const diffInHours = diffInMinutes / 60; // Diferencia en horas
    const diffInDays = diffInHours / 24; // Diferencia en días

    if (diffInMinutes < 60) {
      return `${Math.floor(diffInMinutes)} ${t('minutes')}`;
    } else if (diffInHours < 24) {
      return `${Math.floor(diffInHours)} ${t('hours')}`;
    } else {
      return `${Math.floor(diffInDays)} ${t('days')}`;
    }
  };

  const sendUrl = (reference_id, notification_type, notification_id) => {
    dataService.readedNotification(notification_id);
    if(notification_type == "friendship"){
      history.push(`/user/${reference_id}`);
      handleCloseMenu
    } else if(notification_type == "event"){
      history.push(`/event/${reference_id}`);
      handleCloseMenu
    } else {
      handleCloseMenu
    }
  }

  const handleClearAllNotifications = async () => {
    try {
      await dataService.clearAllNotifications();
      setNotifications([]);
      handleCloseMenu();
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  const getIconByEventType = (eventType) => {
    if (eventType === 'friendship') {
      return '🦄';
    } else if (eventType === 'event') {
      return '🎮';
    } else {
      return '⭐';
    }
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        {notifications.length > 0 ? (
          <>
            {notifications.map((notification, index) => {
              const notificationTitle = t(`notifications.${notification.description}`) + ": " + t(`${notification.notification_type}`) + "#" + notification.reference_id;

              return (
                <NotificationItem
                  key={index}
                  color="text"
                  image={
                    <Icon fontSize="large" sx={{ color: ({ palette: { white } }) => white.main, width: "25px" }}>
                      {getIconByEventType(notification.notification_type)}
                    </Icon>
                  }
                  title={[
                    '', 
                    <span style={{ textTransform: 'none' }}>{notificationTitle}</span>
                  ]}
                  date={formatDate(notification.created_at)}
                  onClick={() => sendUrl(notification.reference_id, notification.notification_type, notification.id)}
                />
              );
            })}

            {/* Añadir el botón para limpiar todas las notificaciones */}
            <VuiBox textAlign="center" mt={2}>
              <VuiButton
                color="error"
                size="small"
                variant="outlined"
                onClick={handleClearAllNotifications}
              >
                {t('notifications.clearAll')}
              </VuiButton>
            </VuiBox>
          </>
        ) : (
          <VuiBox textAlign="center">
            <VuiTypography color="white" variant="sm">{t('noUnreadedNotifications')}</VuiTypography>
          </VuiBox>
        )}
      </Menu>
    );
  };

  const getTranslatedTitle = async (objectId, type) => {
    try {
      if (type == 'game') {
        const game = await dataService.getGameById(objectId);
        return game.resource.title;
      } else if (type == 'event') {
        const event = await dataService.getEventById(objectId);
        return event.resource.name;
      } else if (type == 'user') {
        const user = await dataService.getUserById(objectId);
        return user.resource.get_profile.name;
      } else {
        return objectId;
      }
    } catch (error) {
      console.error('Error fetching game title:', error);
      return gameId;
    }
  };

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={theme => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={theme => navbarContainer(theme)}>
        <VuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={theme => navbarRow(theme, { isMini })}>
          {renderBreadcrumbs()}
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={theme => navbarRow(theme, { isMini })}>
            <VuiBox pr={1} position="relative">
              <VuiInput
                placeholder={t('searchGames')}
                icon={{ component: 'search', direction: 'left' }}
                value={search}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                sx={({ breakpoints }) => ({
                  backgroundColor: 'info.main !important',
                })}
              />
              {searchResults.length > 0 && (
                <VuiBox
                  position="absolute"
                  width="100%"
                  bgcolor="#001540 !important"
                  background="#001540 !important"
                  boxShadow={3}
                  borderRadius="4px"
                  zIndex={2}
                  mt={1}
                >
                  {searchResults.map(game => (
                    <VuiBox
                      key={game.id}
                      p={2}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleSearchSelect(game)}
                    >
                      <VuiTypography color="white" variant="body1">
                        <img src={game.thumbnail || logoNoThumbnail} alt={game.title} style={{ marginRight: 8, width: 50, height: 50, objectFit: 'cover', borderRadius: 4 }} />
                        {game.title}
                      </VuiTypography>
                    </VuiBox>
                  ))}
                </VuiBox>
              )}
            </VuiBox>
            <VuiBox color={light ? 'white' : 'inherit'}>
              <IconButton size="large" color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
                <Icon className={'text-white'}>{miniSidenav ? 'menu_open' : 'menu'}</Icon>
              </IconButton>
              {/*
              <IconButton size="small" title={t(`routes.create-event`)} color="inherit" sx={navbarIconButton} onClick={handleCreateEvent}>
                <CgAdd color="white" />
              </IconButton>
              <IconButton size="small" title={t(`configurations`)} color="inherit" sx={navbarIconButton} onClick={handleConfiguratorOpen}>
                <Icon>settings</Icon>
              </IconButton>
              */}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
                title={t(`notifications.title`)}
              >
                <VuiBadge
                  badgeContent={notifications.length}
                  color="error"
                  size="xs"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Icon className={light ? 'text-white' : 'text-dark'}>notifications</Icon>
                </VuiBadge>
              </IconButton>
            </VuiBox>
          </VuiBox>
        )}
        {renderMenu()}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
