import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
// react-router-dom components
import { Link } from "react-router-dom";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "utils/GradientBorder";
// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";
// Images
import bgSignIn from "assets/images/signIn.jpeg";
import ReCAPTCHA from 'react-google-recaptcha';
// OAUTH
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import rgba from "assets/theme/functions/rgba";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

function SignIn() {
  const { t } = useTranslation();
  const { login, user, oauthLogin } = useContext(AuthContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isWeb, setIsWeb] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || '';
    if (userAgent.includes('Mobile')) {
      setIsWeb(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const currentUser = localStorage.getItem('currentUser');
    if (token && currentUser) {
      history.push('/dashboard');
    }
  }, []);

  useEffect(() => {
    checkLoginStatusByCookies();
    const intervalId = setInterval(checkLoginStatusByCookies, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const checkLoginStatusByCookies = async () => {
    const token = getCookie('token');
    const email = getCookie('email');

    if (token && email) {
      try {
        const decodedEmail = decodeURIComponent(email);
        setLoading(true);
        setIsLoggedIn(true);
        deleteCookie('token');
        deleteCookie('email');
        await oauthLogin(token, decodedEmail, captchaValue, isWeb);
        history.push('/dashboard');
      } catch (error) {
        setErrorMessage(error.message || 'Unknown error');
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteCookie = (name, path = '/', domain = '.meeplefreaks.com') => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain}`;
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const GoogleIconWithText = () => (
    <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
      <FaGoogle style={{ fontSize: '24px', marginRight: '8px' }} />
      <span>Google</span>
    </div>
  );

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (isWeb && !captchaValue) {
        throw new Error(t("captchaError"));
      }
      await login(email, password, captchaValue, isWeb);
      history.push('/dashboard');
      //window.location.href = "/dashboard";
    } catch (error) {
      setError(t("loginError"));
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const openCasdoorPopup = (provider) => {
    const url = process.env.REACT_APP_CASDOOR_OAUTH_LOGIN;
    const width = 600;
    const height = 700;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);
    window.open(url, 'Casdoor Login', `width=${width},height=${height},top=${top},left=${left}`);
  };

  if (loading) {
    return (
      <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <CoverLayout
      title={t("signInTitle")}
      color="white"
      description={t("signInDescription")}
      premotto={t("signInPremotto")}
      motto={t("signInMotto")}
      image={bgSignIn}
      cardContent
    >
      <VuiTypography
        color="white"
        fontWeight="bold"
        textAlign="center"
        mb="24px"
        sx={({ typography: { size } }) => ({
          fontSize: size.lg,
        })}
      >
        {t('registerWith')}
      </VuiTypography>
      <Stack mb="25px" justifyContent="center" alignItems="center" direction="row" spacing={2}>
        {/*
        <GradientBorder borderRadius="xl">
          <IconButton
            onClick={() => openCasdoorPopup('casdoor')}
            transition="all .25s ease"
            justify="center"
            align="center"
            bg="rgb(19,21,54)"
            borderradius="15px"
            sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
              borderRadius: borderRadius.xl,
              padding: "25px",
              backgroundColor: secondary.focus,
              "&:hover": {
                backgroundColor: rgba(secondary.focus, 0.9),
              },
            })}
          >
            <Icon
              as={FaFacebook}
              w="30px"
              h="30px"
              sx={({ palette: { white } }) => ({
                color: white.focus,
              })}
            />
          </IconButton>
        </GradientBorder>
        <GradientBorder borderRadius="xl">
          <IconButton
            onClick={() => openCasdoorPopup('casdoor')}
            transition="all .25s ease"
            justify="center"
            align="center"
            bg="rgb(19,21,54)"
            borderradius="15px"
            sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
              borderRadius: borderRadius.xl,
              padding: "25px",
              backgroundColor: secondary.focus,
              "&:hover": {
                backgroundColor: rgba(secondary.focus, 0.9),
              },
            })}
          >
            <Icon
              as={FaApple}
              w="30px"
              h="30px"
              sx={({ palette: { white } }) => ({
                color: white.focus,
              })}
            />
          </IconButton>
        </GradientBorder>
        */}
        <GradientBorder borderRadius="xl">
          <IconButton
            onClick={() => openCasdoorPopup('casdoor')}
            transition="all .25s ease"
            justify="center"
            align="center"
            bg="rgb(19,21,54)"
            borderradius="15px"
            sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
              borderRadius: borderRadius.xl,
              padding: "25px",
              backgroundColor: secondary.focus,
              "&:hover": {
                backgroundColor: rgba(secondary.focus, 0.9),
              },
            })}
          >
            {/*
            <Icon
              as={FaGoogle}
              w="30px"
              h="30px"
              sx={({ palette: { white } }) => ({
                color: white.focus,
              })}
            />
            */}
            <GoogleIconWithText />
          </IconButton>
        </GradientBorder>
      </Stack>
      <VuiTypography color="white" textAlign="center" mb="16px">
        {t('or')}
      </VuiTypography>
      <VuiBox component="form" onSubmit={handleSubmit} role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              {t("emailLabel")}
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput 
              type="email" 
              placeholder={t('email')} 
              fontWeight="500" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              {t("passwordLabel")}
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder={t('password')}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{t("rememberMe")}
          </VuiTypography>
        </VuiBox>
        {error && (
          <VuiTypography variant="caption" color="error" fontWeight="medium">
            {error}
          </VuiTypography>
        )}
        {isWeb && (
          <VuiBox mt={3}>
            <ReCAPTCHA
              sitekey="6LeLsSkqAAAAAKYxsxHgrhCLT01xKoOjdITSOtAA"
              onChange={handleCaptchaChange}
            />
          </VuiBox>
        )}
        <VuiBox mt={4} mb={1}>
          <VuiButton type="submit" color="info" fullWidth>
            {t("signInButton")}
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            {t("noAccountMessage")}{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              {t("signUp")}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox mt={3} textAlign="center">
        <VuiTypography variant="button" color="text" fontWeight="regular">
          {t("forgotPasswordMessage")}{" "}
          <VuiTypography
            component={Link}
            to="/authentication/forgot-password"
            variant="button"
            color="white"
            fontWeight="medium"
          >
            {t("forgotPassword")}
          </VuiTypography>
        </VuiTypography>
      </VuiBox>
      <VuiBox mt={3} textAlign="center">
        <VuiTypography variant="button" color="text" fontWeight="regular">
          {t("termsAndConditionsMessage")}{" "}
          <VuiTypography
            component={Link}
            to="/terms-and-conditions"
            variant="button"
            color="white"
            fontWeight="medium"
          >
            {t("termsAndConditions")}
          </VuiTypography>
        </VuiTypography>
      </VuiBox>
      <VuiBox mt={3} textAlign="center">
        <VuiTypography variant="button" color="text" fontWeight="regular">
          {t("termsAndConditionsMessage")}{" "}
          <VuiTypography
            component={Link}
            to="/policy-and-privacy"
            variant="button"
            color="white"
            fontWeight="medium"
          >
            {t("policyAndPrivacy")}
          </VuiTypography>
        </VuiTypography>
      </VuiBox>      
    </CoverLayout>
  );

}

export default SignIn;