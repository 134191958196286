import React from 'react';
import VuiButton from "components/VuiButton";
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ShareButton = ({ title, text, url }) => {
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const { t, i18n } = useTranslation();

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: text,
          url: url,
        });
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setOpen(true);
    }
  };

  const handleCopy = () => {
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Tooltip title={t('share')}>
        <VuiButton
          variant="contained"
          color="success"
          startIcon={<ShareIcon fontSize="small" />}
          onClick={handleShare}
          size="small"
          sx={{ minWidth: 'unset' }}
        >
          {t('share')}
        </VuiButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#1a237e', // Fondo azul oscuro
            color: 'white !important', // Letra blanca
            border: '1px solid #4a148c', // Toque morado
          },
        }}
      >
        <DialogTitle sx={{ color: 'white !important' }}>{t('share')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'white !important' }}>
            {t('shareNotAvailable')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label=""
            type="text"
            fullWidth
            value={url}
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleClose} color="light">
            {t('cancel')}
          </VuiButton>
          <CopyToClipboard text={url} onCopy={handleCopy}>
            <VuiButton color="light">
              {t('copy')}
            </VuiButton>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={t('copied')}
      />
    </>
  );
};

export default ShareButton;
