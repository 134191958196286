import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Icon from '@mui/material/Icon';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

function Breadcrumbs({ icon, title, route, light=false }) {
  const { t } = useTranslation();
  const routes = route.slice(0, -1);

  const renderTitle = () => {
    return title.replace("-", " ");
  };

  const renderTranslatedRoute = (segment) => {
    const translatedSegment = t(`routes.${segment}`, { defaultValue: segment });
    if ( segment == 'game' ){
      segment = 'games'
    }
    return (
      <Link to={`/${segment}`} key={segment}>
        <VuiTypography
          component="span"
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          opacity={light ? 0.8 : 0.5}
          sx={{ lineHeight: 0 }}
        >
          {translatedSegment}
        </VuiTypography>
      </Link>
    );
  };

  return (
    <VuiBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <VuiTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </VuiTypography>
        </Link>
        {routes.map((el) => (
          renderTranslatedRoute(el)
        ))}
        <VuiTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {renderTitle()}
        </VuiTypography>
      </MuiBreadcrumbs>
      <VuiTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {renderTitle()}
      </VuiTypography>
    </VuiBox>
  );
}

Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
