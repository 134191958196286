import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import VuiInput from 'components/VuiInput';
import VuiButton from 'components/VuiButton';
import VuiSwitch from 'components/VuiSwitch';
import CountrySelect from 'components/CustomSelects/CountrySelect';
import { MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText, TextField, CircularProgress, Backdrop } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { ReactComponent as PlayGames } from "assets/svgs/playGames.svg";
import logoNoThumbnail from 'assets/images/logo.png';
import { Slider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import dataService from 'api/data.service';
import DashboardLayout from "utils/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utils/Navbars/DashboardNavbar";

const useStyles = makeStyles({
  select: {
    '& .MuiSelect-select, &.MuiSelect-outlined, &.MuiInputBase-input': {
      width: '100% !important',
    },
  },
});

const CreateEventPage = () => {
  const sliderTheme = createTheme({
    palette: {
      primary: {
        main: '#001540',
      },
    },
  });
  const { t } = useTranslation();
  const history = useHistory();
  const [country, setCountry] = useState("ES");
  const [eventType, setEventType] = useState('private');
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGames, setSelectedGames] = useState([]);
  const [error, setError] = useState(null);
  const languageOptions = [
    { value: 'spanish', label: t('languages.spanish') },
    { value: 'english', label: t('languages.english') },
    { value: 'french', label: t('languages.french') },
    { value: 'german', label: t('languages.german') },
    { value: 'japanese', label: t('languages.japanese') },
  ];
  const eventTypeOptions = [
    { value: 'private', label: t('privateEvent') },
    { value: 'public', label: t('openEvent') },
    //{ value: 'invite_only', label: t('inviteOnly') },
  ];
  const classes = useStyles();
  const [eventData, setEventData] = useState({
    name: '',
    description: '',
    event_date: '',
    duration: 20,
    percentage: '',
    min_players: 1,
    max_players: 2,
    event_type: 'private',
    visible: true,
    allow_guest_notes: false,
    address: '',
    city: '',
    state: '',
    country: 'ES',
    postal_code: '',
    image: '',
    languages: [],
    games: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEventData({
      ...eventData,
      [name]: checked,
    });
  };

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    setEventData({
      ...eventData,
      languages: value,
    });
  };

  const handleCountryChange = (value) => {
    setEventData({
      ...eventData,
      country: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setError("");
      const resultEvent = await dataService.createEvent({
        ...eventData,
        games: selectedGames.map(game => game.id),
      });
      history.push(`/event/${resultEvent.resource.id}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);
  };

  const handleSearchChange = async (event, value) => {
    setSearch(value);
    if (value.length > 2) {
      setLoading(true);
      try {
        const results = await dataService.discoverGames(1, 50, value);
        setSearchResults(results.resources);
      } catch (error) {
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setEventData({
      ...eventData,
      participation_percentage: newValue,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox sx={{ padding: 3 }}>
        <VuiBox mt={12} textAlign="center" xs={12} md={12} xl={12} color="light">
          <PlayGames style={{ width: '15%', height: '15%' }} />
          <VuiTypography variant="h4" mb={3} color="light">
            {t('createEvent')}
          </VuiTypography>
        </VuiBox>
        {error && (
          <VuiBox mb={3}>
            <VuiTypography color="error">{error}</VuiTypography>
          </VuiBox>
        )}
        <form onSubmit={handleSubmit}>
          <VuiBox>
            <VuiTypography variant="body1" color="light">
              {t('games')}
            </VuiTypography>
            <Autocomplete
              multiple
              options={searchResults}
              getOptionLabel={(option) => option.title}
              value={selectedGames}
              onChange={(event, newValue) => setSelectedGames(newValue)}
              filterSelectedOptions
              noOptionsText={t('noOptions')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  onChange={(e) => handleSearchChange(e, e.target.value)}
                  placeholder={t('addGames')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: 'white !important' }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#001540 !important',
                      color: 'white !important',
                      '& fieldset': {
                        borderColor: '#001540 !important',
                      },
                      '&:hover fieldset': {
                        borderColor: '#001540 !important',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#001540 !important',
                      },
                      '& input::placeholder': {
                        color: 'white !important',
                        opacity: 1,
                      },
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    backgroundColor: '#001540',
                    color: 'white',
                  }}
                >
                  <img src={option.thumbnail || logoNoThumbnail} alt={option.title} style={{ marginRight: 8, width: 75, height: 75, objectFit: 'cover', borderRadius: 4 }} />
                  {option.title}
                </li>
              )}
              PaperComponent={(params) => (
                <VuiBox
                  {...params}
                  sx={{
                    backgroundColor: '#001540',
                    color: 'white',
                  }}
                />
              )}
              ListboxProps={{
                style: {
                  backgroundColor: '#001540',
                  color: 'white',
                },
              }}
            />
          </VuiBox>
          <VuiBox sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('name')}
                </VuiTypography>
                <VuiInput
                  name="name"
                  value={eventData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('eventDate')}
                </VuiTypography>
                <VuiInput
                  type="datetime-local"
                  name="event_date"
                  value={eventData.event_date}
                  onChange={handleChange}
                  fullWidth
                  required
                  sx={{
                    '& input': {
                      color: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#001540',
                      },
                      '&:hover fieldset': {
                        borderColor: '#001540',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#001540',
                      },
                    },
                    '& .MuiInputAdornment-root': {
                      color: 'white',
                    },
                    '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
                      filter: 'invert(1)',  // Cambia el color del icono a blanco
                    },
                    '& input::placeholder': {
                      color: 'white',
                      opacity: 1,
                    },
                  }}
                />
              </VuiBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('eventDescription')}
                </VuiTypography>
                <VuiInput
                  type="textarea"
                  rows={4}
                  multiline
                  name="description"
                  value={eventData.description}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </VuiBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('duration')}
                </VuiTypography>
                <VuiInput
                  name="duration"
                  type="number"
                  value={eventData.duration}
                  onChange={handleChange}
                  fullWidth
                  required
                  inputProps={{
                    min: 10,
                    max: 1800,
                  }}
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('minPlayers')}
                </VuiTypography>
                <VuiInput
                  name="min_players"
                  type="number"
                  value={eventData.min_players}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    min: 1,
                    max: 100,
                  }}
                  required
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('maxPlayers')}
                </VuiTypography>
                <VuiInput
                  type="number"
                  name="max_players"
                  value={eventData.max_players}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    min: 2,
                    max: 100,
                  }}
                  required
                />
              </VuiBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="subtitle1" color="light">{t('eventType')}</VuiTypography>
                <FormControl fullWidth>
                  <InputLabel id="event-type-label"></InputLabel>
                  <Select
                    labelId="event-type-label"
                    id="event_type"
                    value={eventType}
                    onChange={handleEventTypeChange}
                    multiple={false}
                    classes={classes}
                    fullWidth
                    sx={{
                      cursor: 'pointer !important',
                      backgroundColor: 'rgba(15, 21, 53, 0.87) !important',
                      color: 'white !important',
                      '&:hover': {
                        backgroundColor: 'rgba(15, 21, 53, 0.87) !important',
                      },
                      position: "absolute",
                    }}
                  >
                    {eventTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('languages.title')}
                </VuiTypography>
                <FormControl fullWidth>
                  <InputLabel id="languages-label" />
                  <Select
                    labelId="languages-label"
                    id="languages"
                    multiple
                    value={eventData.languages}
                    onChange={handleLanguageChange}
                    classes={classes}
                    renderValue={(selected) => selected.map((lang) => t(`languages.${lang}`)).join(', ')}
                    fullWidth
                    sx={{
                      cursor: 'pointer !important',
                      backgroundColor: 'rgba(15, 21, 53, 0.87) !important',
                      color: 'white !important',
                      '&:hover': {
                        backgroundColor: 'rgba(15, 21, 53, 0.87) !important',
                      },
                      position: "absolute",
                    }}
                  >
                    {languageOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox checked={eventData.languages.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('percentage')}
                </VuiTypography>
                <ThemeProvider theme={sliderTheme}>
                  <Slider
                    value={eventData.percentage}
                    onChange={(e, newValue) => setEventData({ ...eventData, percentage: newValue })}
                    aria-labelledby="percentage-slider"
                    min={0}
                    max={100}
                    step={1}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}%`}
                  />
                </ThemeProvider>
              </VuiBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('address')}
                </VuiTypography>
                <VuiInput
                  name="address"
                  value={eventData.address}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </VuiBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('city')}
                </VuiTypography>
                <VuiInput
                  name="city"
                  value={eventData.city}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('state')}
                </VuiTypography>
                <VuiInput
                  name="state"
                  value={eventData.state}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('country')}
                </VuiTypography>
                <CountrySelect
                  value={eventData.country}
                  onChange={handleCountryChange}
                />
              </VuiBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('postalCode')}
                </VuiTypography>
                <VuiInput
                  name="postal_code"
                  value={eventData.postal_code}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('visible')}
                </VuiTypography>
                <VuiSwitch
                  color="info"
                  checked={eventData.visible}
                  onChange={handleCheckboxChange}
                  name="visible"
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('allowGuestNotes')}
                </VuiTypography>
                <VuiSwitch
                  color="info"
                  checked={eventData.allow_guest_notes}
                  onChange={handleCheckboxChange}
                  name="allow_guest_notes"
                />
              </VuiBox>
            </div>
          </VuiBox>
          <VuiBox mt={3} textAlign="left">
            <VuiButton type="submit" variant="contained" color="success" disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : t('create')}
            </VuiButton>
          </VuiBox>
        </form>
        <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </VuiBox>
    </DashboardLayout>
  );
};

export default CreateEventPage;
