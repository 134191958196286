import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import SpainFlag from 'assets/flags/es.svg';
import UKFlag from 'assets/flags/gb.svg';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || i18n.language);

  useEffect(() => {
    // Set the initial language based on localStorage
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "es" : "en";
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  return (
    <Button 
      onClick={toggleLanguage} 
      style={{ color: "white", fontSize: "1.0rem" }}
    >
      {currentLanguage === "en" ? (
        <>
          <img 
            src={UKFlag} 
            alt="UK Flag" 
            style={{ width: '20px', height: '15px', marginLeft: '8px' }} 
          />
          English 
        </>
      ) : (
        <>
          <img 
            src={SpainFlag} 
            alt="Spain Flag" 
            style={{ width: '20px', height: '15px', marginLeft: '8px' }} 
          />
          Español 
        </>
      )}
    </Button>
  );
}

export default LanguageSwitcher;