import React, { useState, useEffect } from "react";
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import dataService from 'api/data.service';
import VuiBadge from "components/VuiBadge";
import RankingList from "utils/Rankings/RankingList";
import RankingItem from "utils/Rankings/RankingItem";
import { Tabs, Tab } from '@mui/material';
import { FaMedal } from "react-icons/fa";
import { Link } from "react-router-dom";
import Table from "utils/Tables/Table";

function DefaultEventCard({ image, label, title, description, owner, actions = [], options = [], initialNotes = [], scores = [], participants = [], event }) {
  const { t, i18n } = useTranslation();
  const [comment, setComment] = useState("");
  const [notes, setNotes] = useState(initialNotes);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [canLeaveComment, setCanLeaveComment] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [pendingParticipants, setPendingParticipants] = useState([]);
  const [acceptedParticipants, setAcceptedParticipants] = useState([]);
  const [rejectedParticipants, setRejectedParticipants] = useState([]);

  const EventTypeTag = ({ eventType }) => {
    const getTagColor = (eventType) => {
      switch (eventType) {
        case 'public':
          return 'info';
        case 'private':
          return 'warning';
        default:
          return 'text';
      }
    };

    return (
      <VuiBadge
        badgeContent={eventType === 'public' ? t('openEvent') : t('privateEvent')}
        size="xs"
        color={getTagColor(eventType)}
        container
        ml={1}
      />
    );
  };

  const VisibilityTag = ({ visible }) => {
    return (
      <VuiBadge
        badgeContent={visible ? t('visibleEvent') : t('invisibleEvent')}
        size="xs"
        color={visible ? 'success' : 'error'}
        container
        ml={1}
      />
    );
  };


  const LanguagesTag = ({ languages }) => {

    return (
      <>
        {languages.map((language, index) => (
          <VuiBadge
            key={index}
            badgeContent={t(`languages.${language}`)}
            size="xs"
            color="primary"
            container
            sx={{ marginRight: '4px' }}
          />
        ))}
      </>
    );
  };

  const formatDate = (date) => {
    const eventDate = new Date(date);
    const year = eventDate.getFullYear();
    const month = String(eventDate.getMonth() + 1).padStart(2, '0');
    const day = String(eventDate.getDate()).padStart(2, '0');
    const hours = String(eventDate.getHours()).padStart(2, '0');
    const minutes = String(eventDate.getMinutes()).padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };
  const sortedNotes = [...notes].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const renderOptions = options.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <VuiAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { dark }, functions: { rgba } }) => ({
          border: `${borderWidth[2]} solid ${rgba(dark.focus, 0.5)}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,
          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  const commentsTitle = t('commentsTitle');
  const noCommentsMessage = t('noComments');

  const loadNotes = async () => {
    try {
      const data = await dataService.getEventById(event.id);
      setNotes(data.resource.event_notes);
    } catch (error) {
    }
  };

  const checkIfOwner = async () => {
    try {
      const profile_data = await dataService.getSessionProfile();
      const isAParticipant = !!participants.find(p => p.id === profile_data.resource.id);
      const isTheOwner = owner.id === profile_data.resource.id;
      const currentUserCanLeaveComment = (
        (event.allow_guest_notes || isTheOwner) &&
        (event.status === 'pending' || event.status === 'in_progress') &&
        (isAParticipant || isTheOwner)
      );
      if(isTheOwner){
        setIsOwner(isTheOwner);
        setPendingParticipants(event.pending_participant_profiles || []);
        setAcceptedParticipants(event.accepted_participant_profiles || []);
        setRejectedParticipants(event.rejected_participant_profiles || []);
      }
      setCanLeaveComment(currentUserCanLeaveComment);
      return canLeaveComment;
    } catch (error) {
      //console.error("Error loading profile:", error);
    }
  };

  useEffect(() => {
    checkIfOwner();
    loadNotes();
  }, [event.id]);

  const handleSendComment = async () => {
    if (comment.trim().length > 0) {
      setIsButtonDisabled(true);
      try {
        const data = await dataService.addEventNote(event.id, comment);
        const newNote = data.resource;
        setComment("");
        loadNotes();
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 5000);
      } catch (error) {
        //console.error(error.message);
        setIsButtonDisabled(false);
      }
    }
  };

  const actionHandlers = {
    accept: async (participantId) => {
      try {
        await dataService.acceptParticipantRequest(event.id, participantId);
        setPendingParticipants(pendingParticipants.filter(p => p.id !== participantId));
        setAcceptedParticipants([...acceptedParticipants, pendingParticipants.find(p => p.id === participantId)]);
      } catch (error) {
        //console.error("Error accepting request:", error);
      }
    },
    reject: async (participantId) => {
      try {
        await dataService.rejectParticipantRequest(event.id, participantId);
        setPendingParticipants(pendingParticipants.filter(p => p.id !== participantId));
        setRejectedParticipants([...rejectedParticipants, pendingParticipants.find(p => p.id === participantId)]);
      } catch (error) {
        //console.error("Error rejecting request:", error);
      }
    },
    pending: async (participantId) => {
      try {
        await dataService.pendingParticipantRequest(event.id, participantId);
        setRejectedParticipants(rejectedParticipants.filter(p => p.id !== participantId));
        setAcceptedParticipants(acceptedParticipants.filter(p => p.id !== participantId));
        const fromRejected = rejectedParticipants.find(p => p.id === participantId);
        const fromAccepted = acceptedParticipants.find(p => p.id === participantId);
        if (fromRejected) {
          setPendingParticipants([...pendingParticipants, fromRejected]);
        } else if (fromAccepted) {
          setPendingParticipants([...pendingParticipants, fromAccepted]);
        }
      } catch (error) {
        //console.error("Error rejecting request:", error);
      }
    },
    remove: async (participantId) => {
      try {
        await dataService.removeParticipantRequest(event.id, participantId);
        setAcceptedParticipants(acceptedParticipants.filter(p => p.id !== participantId));
        setRejectedParticipants(rejectedParticipants.filter(p => p.id !== participantId));
      } catch (error) {
        //console.error("Error removing participant:", error);
      }
    }
  };

  const participantsTabs = () => {
    const [tabValue, setTabValue] = useState('pending');

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    if (!isOwner) return null;

    return (
      <VuiBox mb={3} sx={{ background: 'transparent' }}>
        <Tabs value={tabValue} onChange={handleTabChange} textColor="inherit" indicatorColor="primary" sx={{ background: 'transparent' }}>
          <Tab
            label={`${t('pendingRequests')} (${pendingParticipants.length})`}
            value="pending"
            sx={{
              backgroundColor: tabValue === 'pending' ? 'warning.main' : 'transparent',
              '&.Mui-selected': {
                backgroundColor: 'warning.main',
              }
            }}
          />
          <Tab
            label={`${t('acceptedRequests')} (${acceptedParticipants.length})`}
            value="accepted"
            sx={{
              backgroundColor: tabValue === 'accepted' ? 'success.main' : 'transparent',
              '&.Mui-selected': {
                backgroundColor: 'success.main',
              }
            }}
          />
          <Tab
            label={`${t('rejectedRequests')} (${rejectedParticipants.length})`}
            value="rejected"
            sx={{
              backgroundColor: tabValue === 'rejected' ? 'error.main' : 'transparent',
              '&.Mui-selected': {
                backgroundColor: 'error.main',
              }
            }}
          />
        </Tabs>
        <VuiBox mt={2}>
          {tabValue === 'pending' && renderParticipantsList(
            pendingParticipants,
            t('pendingPlayerRequests'),
            ['accept', 'reject'],
            'pending'
          )}
          {tabValue === 'accepted' && renderParticipantsList(
            acceptedParticipants,
            t('acceptedParticipants'),
            ['pending'],
            'accepted'
          )}
          {tabValue === 'rejected' && renderParticipantsList(
            rejectedParticipants,
            t('rejectedParticipants'),
            ['pending', 'remove'],
            'rejected'
          )}
        </VuiBox>
      </VuiBox>
    );
  };

  const renderParticipantsList = (participantsList, title, actionNames, type) => {
    if (!isOwner || participantsList.length === 0) return null;

    const backgroundColor = {
      accepted: 'rgba(76, 175, 80, 0.1)',   // Light green (10% opacity)
      rejected: 'rgba(244, 67, 54, 0.1)',   // Light red (10% opacity)
      pending: 'rgba(255, 193, 7, 0.1)'     // Light yellow (10% opacity)
    }[type];

    const columns = [
      { name: t('user'), align: 'left' },
      { name: t('actions'), align: 'center' },
    ];

    const rows = participantsList.map((participant) => ({
      [t('user')]: (
        <VuiBox display="flex" alignItems="center">
          <Link to={`/user/${participant.id}`} style={{ textDecoration: "none", color: "inherit" }}>
            <VuiBox display="flex" alignItems="center">
              <VuiAvatar src={participant.avatar} alt={participant.display_name} size="xs" />
              <VuiTypography variant="button" fontWeight="medium" color="text" ml={2}>
                {participant.display_name}
              </VuiTypography>
            </VuiBox>
          </Link>
        </VuiBox>
      ),
      [t('actions')]: (
        <VuiBox display="flex" gap={1}>
          {actionNames.map(actionName => {
            const { color, label } = {
              accept: { color: 'success', label: t('accept') },
              reject: { color: 'error', label: t('reject') },
              pending: { color: 'warning', label: t('setPendingRequest') },
              remove: { color: 'error', label: t('removeRequest') }
            }[actionName];

            return (
              <VuiButton
                key={actionName}
                variant="outlined"
                size="small"
                color={color}
                onClick={() => actionHandlers[actionName](participant.id)}
              >
                {label}
              </VuiButton>
            );
          })}
        </VuiBox>
      ),
    }));

    return (
      <VuiBox mb={3}>
        <VuiTypography variant="h5" color="white" fontWeight="bold" mb={2}>
          {title}
        </VuiTypography>
        <VuiBox sx={{ backgroundColor, padding: '16px', borderRadius: '8px' }}>
          <Table
            columns={columns}
            rows={rows}
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          />
        </VuiBox>
      </VuiBox>
    );
  };

  const renderNotes = (
    <VuiBox mb={3} overflow="auto">
      <VuiTypography variant="h6" color="text">
        {sortedNotes.length > 0 ? commentsTitle : noCommentsMessage}
      </VuiTypography>
      <VuiBox mb={3} maxHeight="300px" overflow="auto">
        {sortedNotes.length > 0 ? (
          sortedNotes.map((note, index) => {
            const participant = participants.find(p => p.id === note.user_id);
            if (!participant) return null;
            
            return (
              <VuiBox
                key={index}
                display="flex"
                flexDirection="column"
                mb={2}
                ml={2}
                p={2}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                }}
              >
                <VuiBox display="flex" alignItems="center" mb={1}>
                  <VuiAvatar
                    src={participant.avatar}
                    alt={participant.display_name}
                    size="xs"
                    sx={({ borders: { borderWidth }, palette: { dark }, functions: { rgba } }) => ({
                      border: `${borderWidth[2]} solid ${rgba(dark.focus, 0.5)}`,
                      cursor: "pointer",
                      position: "relative",
                      ml: -1.25,
                      "&:hover, &:focus": {
                        zIndex: "10",
                      },
                    })}
                  />
                  <VuiTypography variant="button" fontWeight="medium" color="white" ml={2}>
                    {participant.display_name}
                  </VuiTypography>
                </VuiBox>
                <VuiBox ml={5}>
                  <VuiTypography variant="caption" fontWeight="regular" color="white">
                    {note.content}
                  </VuiTypography>
                  <VuiTypography variant="caption" fontWeight="regular" color="text" mt={0.5}>
                    {" "}{formatDate(note.created_at)}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            );
          })
        ) : null}
      </VuiBox>
      {canLeaveComment && (
        <VuiBox display="flex" flexDirection="column" mb={2} ml={2} p={2}
          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}
        >
          <VuiBox display="flex">
            <textarea
              rows={3} // Máximo de 5 filas
              style={{ width: '100%', resize: 'none' }}
              maxLength={200} // Máximo de 200 caracteres
              placeholder={t('leaveComment')}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
            <VuiButton variant="outlined" size="small" onClick={handleSendComment} disabled={isButtonDisabled}>
              {" "}{t('send')}
            </VuiButton>
          </VuiBox>
        </VuiBox>
      )}
    </VuiBox>
  );

  const renderScores = () => {
    if (scores.length === 0) {
      return null;
    }

    // Ordenar scores por podium_position y score (descendente)
    const sortedScores = scores.slice().sort((a, b) => {
      if (a.podium_position !== b.podium_position) {
        return a.podium_position - b.podium_position;
      } else {
        return b.score - a.score;
      }
    });

    // Función para determinar el color e icono basado en la posición y empate
    const getColorAndIcon = (index, score) => {
      let color;
      let icon;

      // Determinar color e icono según la posición y empate
      switch (index) {
        case 0:
          color = "warning"; // Oro
          icon = <FaMedal size="16px" color="gold" />;
          break;
        case 1:
          color = "secondary"; // Plata
          icon = <FaMedal size="16px" color="silver" />;
          break;
        case 2:
          color = "error"; // Cobre
          icon = <FaMedal size="16px" color="#cd7f32" />;
          break;
        default:
          color = "success"; // Color estándar
          icon = <FaMedal size="16px" color="blue" />;
          break;
      }

      return { color, icon };
    };

    let currentPosition = 0;

    return (
      <VuiBox mb={3} overflow="auto">
        <RankingList title={t('victoryBoard')}>
          {sortedScores.map((score, index) => {

            const participant = participants.find(p => p.id === score.user_id);
            if (!participant) return null;

            const { color, icon } = getColorAndIcon(currentPosition, score);

            // Incrementar la posición solo si el score actual es diferente al anterior
            if (index > 0 && sortedScores[index - 1].podium_position !== score.podium_position) {
              currentPosition++;
            }

            // Determinar el nombre del badge según la posición actual
            let badge;
            switch (currentPosition) {
              case 0:
                badge = t("firstPlace");
                break;
              case 1:
                badge = t("secondPlace");
                break;
              case 2:
                badge = t("thirdPlace");
                break;
              default:
                badge = "";
                break;
            }

            return (
              <RankingItem
                key={index}
                color={color}
                icon={icon}
                username={participant.display_name}
                avatar={participant.avatar}
                description={score.description}
                badges={[badge]}
                score={score.score}
                lastItem={index === sortedScores.length - 1}
              />
            );
          })}
        </RankingList>
      </VuiBox>
    );
  };

  return (
    <VuiBox
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      {image && image.length > 0 && (
        <VuiBox
          component="img"
          src={image}
          mb="8px"
          borderRadius="15px"
          sx={({ breakpoints }) => ({
            [breakpoints.up("xl")]: {
              height: "auto",
              maxWidth: "50%",
            },
          })}
        />
      )}
      <VuiBox
        sx={({ breakpoints }) => ({
          [breakpoints.only("xl")]: {
            minHeight: "200px",
          },
        })}
      >
        <VuiBox>
          <VuiTypography variant="xxs" color="text" fontWeight="medium">
            {title}
          </VuiTypography>
        </VuiBox>
        <VuiBox>
          <EventTypeTag eventType={event.event_type} />{" "}
          <VisibilityTag visible={event.visible} />{" "}
          <LanguagesTag languages={event.languages} />
        </VuiBox>
        <VuiBox mb={1}>
          <VuiTypography
            variant="h5"
            color="white"
          >
            {label}
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={3} lineHeight={0} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          <VuiTypography variant="button" fontWeight="regular" color="text">
            <VuiTypography
              variant="h6"
              fontWeight="regular"
              color="text"
              dangerouslySetInnerHTML={{ __html: description }}
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
            />
          </VuiTypography>
        </VuiBox>
        {renderScores()}
        {renderNotes}
        {event.status === "pending" && (
          participantsTabs()
        )}
        <VuiBox display="flex" justifyContent="space-between" alignItems="center">
          {actions.map((action, index) => (
            <VuiButton
              key={index}
              component={action.type === "internal" ? Link : "a"}
              to={action.type === "internal" ? action.route : null}
              href={action.type === "external" ? action.route : null}
              target={action.type === "external" ? "_blank" : null}
              rel={action.type === "external" ? "noreferrer" : null}
              variant="outlined"
              size="small"
              color={action.color}
              placeholder={action.label}
              onClick={action.onClick}
            >
              {action.label}
              {action.icon}
            </VuiButton>
          ))}
          <VuiBox display="flex">{renderOptions}</VuiBox>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
}

// Validación de tipos de propiedades
DefaultEventCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    birthday: PropTypes.string.isRequired,
    about_me: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    settings: PropTypes.any,
    collection_count: PropTypes.number.isRequired,
    friends_count: PropTypes.number.isRequired,
    events_count: PropTypes.number.isRequired,
    give_me_the_money: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]),
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "white",
        "text",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "white",
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  notes: PropTypes.arrayOf(PropTypes.shape({
    user_id: PropTypes.number,
    content: PropTypes.string,
    created_at: PropTypes.string,
  })),
  participants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    display_name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  })).isRequired,
  scores: PropTypes.arrayOf(PropTypes.object),
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    allow_guest_notes: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    event_type: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    languages: PropTypes.arrayOf(PropTypes.string),
    pendingParticipants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      display_name: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    }))
  }).isRequired,
};

export default DefaultEventCard;
