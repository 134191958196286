import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dataService from 'api/data.service';

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from '@mui/material/Tooltip';
import VuiAvatar from "components/VuiAvatar";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import DashboardLayout from "utils/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utils/Navbars/DashboardNavbar";
import Footer from "utils/Footer";
// Overview page components
import DefaultEventCard from "utils/Cards/EventCard/DefaultEventCard";
import HeaderEventCard from "utils/Cards/EventCard/HeaderEventCard";
import EventInfoCard from "utils/Cards/InfoCards/EventInfoCard";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from '@mui/icons-material/YouTube';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon } from '@fortawesome/free-brands-svg-icons';

import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

function Overview() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const eventImage = (games) => {
    if (event.image) {
      return event.image;
    }
    else if (games.length === 1){
      return games[0].thumbnail
    }
    else {
      return ""
    }
  }
  const avatars = (players) => {
    if (players.length === 0) {
      return "N/A";
    }
    return players.map((player, index) => (
      <Tooltip key={index} title={player.display_name} placement="bottom">
        <VuiAvatar
          src={player.avatar}
          alt={player.display_name}
          size="xs"
          sx={{
            border: ({
              borders: { borderWidth },
              palette: { dark },
            }) => `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",
            "&:not(:first-of-type)": {
              ml: -1.25,
            },
            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));
  };
  const calculateEndTime = (event, t) => {
      const startTime = new Date(event.event_date);
      const durationMs = event.duration * 60 * 1000;
      const endTime = new Date(startTime.getTime() + durationMs);
      const options = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
      };
      const formattedStartTime = startTime.toLocaleString(t('locale'), options);
      const formattedEndTime = endTime.toLocaleString(t('locale'), { hour: 'numeric', minute: 'numeric', hour12: false });
      const capitalizedDay = formattedStartTime.replace(/^\w/, (c) => c.toUpperCase());
      const result = `${capitalizedDay} ${t('to')} ${formattedEndTime}`;
      return result;
  };
  const formatEventDate = (event, t) => {
      const eventDate = new Date(event.event_date); // Convert event date to Date object
      const options = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
      };
      let formattedEventDate = eventDate.toLocaleString(t('locale'), options);
      formattedEventDate = formattedEventDate.replace(/^\w/, (c) => c.toUpperCase());
      return formattedEventDate;
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const data = await dataService.getEventById(id);
        const profile_data = await dataService.getSessionProfile();
        setProfile(profile_data.resource);
        setEvent(data.resource);
      } catch (err) {
        //console.error(err);
        history.push('/played-games');
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id, history]);

  if (loading) {
    return (
      <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (!event) {
    return <div>{t('not_found')}</div>;
  }

  const games = event.games;
  const participants = event.participant_profiles;
  const currentVacancies = event.max_players - participants.length;
  const currentUserStatus = event.participant_statuses.find(status => status[0] === profile.id);
  const userEventStatus = currentUserStatus ? currentUserStatus[1] : null;
  const userEventRating = currentUserStatus ? currentUserStatus[2] : 0;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={5} mb={3}>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid item xs={12} md={12} xl={12}>
            <HeaderEventCard
              image={eventImage(games)}
              label={event.name}
              title={event.name}
              description={games.map(game => game.title).join(', ')}
              owner={event.owner_profile}
              user={profile}
              games={games}
              event_id={event.id}
              join_status={userEventStatus}
              user_rating={userEventRating}
              event_status={event.status}
              event_bookmarked={event.bookmarked_by_user_ids.includes(profile.id)}
              actions={[]}
              options={[]}
            />
          </Grid>
        </Grid>
        <VuiBox my={2}>
          <hr />
        </VuiBox>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid item xs={12} md={8} xl={9}>
            <DefaultEventCard
              image=""
              label={event.name}
              title={games.map(game => game.title).join(', ')}
              description={event.description}
              owner={event.owner_profile}
              initialNotes={event.event_notes}
              scores={event.scores}
              participants={participants}
              event={event}
              actions={[]}
              options={[]}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <EventInfoCard
              image={eventImage(games)}
              title={event.name}
              description={games.map(game => game.title).join(', ')}
              games={games}
              latitude={event.latitude}
              longitude={event.longitude}
              info={{
                date: `${formatEventDate(event, t)}`,
                duration: `${calculateEndTime(event, t)}`,
                rating: event.rating,
                status: event.status,
                completion:  (
                  <VuiBox width="8rem" textAlign="left">
                    <VuiTypography
                      color="white"
                      variant="button"
                      fontWeight="bold"
                    >
                      {event.percentage}%
                    </VuiTypography>
                    <VuiProgress
                      value={event.percentage}
                      color="info"
                      label={false}
                      sx={{ background: "#2D2E5F" }}
                    />
                  </VuiBox>
                ),
                game: games[0].title,
                players:  (
                  <VuiBox display="flex" py={1}>
                    {avatars(participants)}
                  </VuiBox>
                ),
                vacancies: currentVacancies,
                address: event.full_address,
                location: 'showIt',
              }}
              social={[]}
              sellers={[]}
            />
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
