import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_MEEPLE_FREAKS + '/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

const loginService = {
  signIn: async (email, password, captchaValue, isWeb) => {
    try {
      const response = await api.post('/sessions/signin', { email: email, password: password, captchaValue: captchaValue, isWeb: isWeb })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  signOut: async (token) => {
    try {
      const response = await api.post('/session/signout', { token: token });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  newLogin: async () => {
    try {
      const response = await api.get('/sessions/new');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  emailVerification: async (email, captchaValue, isWeb) => {
    try {
      const response = await api.post('/sessions/email-verification', { email: email, captchaValue: captchaValue, isWeb: isWeb })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  forgotPassword: async (email, captchaValue, isWeb) => {
    try {
      const response = await api.post('/users/forgot-password', { email: email, captchaValue: captchaValue, isWeb: isWeb  })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  resetPassword: async (token, password, captchaValue, isWeb) => {
    try {
      const response = await api.post('/users/reset-password', { token: token, password: password, captchaValue: captchaValue, isWeb: isWeb })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
};

export default loginService;
