// Importación de componentes de Material-UI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Importación de componentes de Vision UI Dashboard React
import VuiButton from "components/VuiButton";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Estilos personalizados para el SidenavCard
import { card, cardContent, cardIconBox, cardIcon } from "utils/Sidenav/styles/sidenavCard";

// Contexto de Vision UI Dashboard React
import { useVisionUIController } from "context";

// React i18n
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "components/VuiTranslate";

function SidenavCard({ color, ...rest }) {
  const { t } = useTranslation();
  const [controller] = useVisionUIController();
  const { miniSidenav, sidenavColor } = controller;

  return (
    <Card sx={(theme) => card(theme, { miniSidenav })}>
      <CardContent sx={(theme) => cardContent(theme, { sidenavColor })}>
        <LanguageSwitcher />
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
