import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import VuiInput from 'components/VuiInput';
import VuiButton from 'components/VuiButton';
import VuiSwitch from 'components/VuiSwitch';
import CountrySelect from 'components/CustomSelects/CountrySelect';
import { MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText, TextField, CircularProgress, Backdrop } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import EditEventCard from "utils/Cards/EventCard/EditEventCard";
import logoNoThumbnail from 'assets/images/logo.png';
import Grid from "@mui/material/Grid";
import VuiBadge from "components/VuiBadge";
import dataService from 'api/data.service';
import DashboardLayout from 'utils/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'utils/Navbars/DashboardNavbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Slider } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Link from "@mui/material/Link";

const useStyles = makeStyles({
  select: {
    '& .MuiSelect-select, &.MuiSelect-outlined, &.MuiInputBase-input': {
      width: '100% !important',
    },
  },
  inputRoot: {
    backgroundColor: '#001540 !important',
    color: 'white !important',
    '& fieldset': {
      borderColor: '#001540 !important',
    },
    '&:hover fieldset': {
      borderColor: '#001540 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#001540 !important',
    },
    '& input::placeholder': {
      color: 'white !important',
      opacity: 1,
    },
  },
  inputLabel: {
    color: 'white !important',
  },
  listbox: {
    backgroundColor: '#001540',
    color: 'white',
  },
  listItem: {
    backgroundColor: '#001540',
    color: 'white',
  },
});

const EditEventPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [country, setCountry] = useState('ES');
  const [eventType, setEventType] = useState('private');
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGames, setSelectedGames] = useState([]);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);
  const [eventData, setEventData] = useState({
    name: '',
    event_date: '',
    description: '',
    min_players: '',
    max_players: '',
    percentage: '',
    event_type: 'private',
    languages: [],
    address: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'ES',
    visible: false,
    allow_guest_notes: false,
    games: [],
  });
  const sliderTheme = createTheme({
    palette: {
      primary: {
        main: '#001540',
      },
    },
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');

  const handleClickOpen = (type) => {
    setDialogType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogType('');
  };

  const handleConfirm = async () => {
    setOpen(false);
    try {
      setError('');
      await dataService.updateEvent(id, {
        ...eventData,
        games: selectedGames.map((game) => game.id),
      });
      history.push(`/event/${id}`);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = async () => {
    setOpen(false);
    try {
      setError('');
      await dataService.deleteEvent(id);
      history.push(`/upcoming-games`);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const data = await dataService.getEventById(id);
        const profile_data = await dataService.getSessionProfile();
        setProfile(profile_data.resource);
        setEventData(data.resource);
        if (profile_data.resource.id !== data.resource.owner_profile.id) {
          history.push('/upcoming-games');
        }
        if (data.resource.status != 'pending') {
          history.push(`/event/${data.resource.id}`);
        }
        setSelectedGames(data.resource.games);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'event_date') {
      const date = new Date(value);
      setEventData(prevData => ({
        ...prevData,
        [name]: date.toISOString()
      }));
    } else {
      setEventData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEventData({
      ...eventData,
      [name]: checked,
    });
  };

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    setEventData({
      ...eventData,
      languages: value,
    });
  };

  const handleCountryChange = (value) => {
    setEventData({
      ...eventData,
      country: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClickOpen('edit');
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    handleClickOpen('delete');
  };

  const handleSearchChange = async (event, value) => {
    setSearch(value);
    if (value.length > 2) {
      setLoading(true);
      try {
        const results = await dataService.discoverGames(1, 50, value);
        setSearchResults(results.resources);
      } catch (error) {
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchResults([]);
    }
  };

  const languageOptions = [
    { value: 'spanish', label: t('languages.spanish') },
    { value: 'english', label: t('languages.english') },
    { value: 'french', label: t('languages.french') },
    { value: 'german', label: t('languages.german') },
    { value: 'japanese', label: t('languages.japanese') },
  ];

  const eventTypeOptions = [
    { value: 'private', label: t('privateEvent') },
    { value: 'public', label: t('openEvent') },
    //{ value: 'invite_only', label: t('inviteOnly') },
  ];

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
    date.setHours(date.getHours() + 2);
    return date.toISOString().slice(0, 16);
  };

  const eventImage = (games) => {
    if (eventData.image) {
      return eventData.image;
    }
    else if (games.length === 1){
      return games[0].thumbnail
    }
    else {
      return ""
    }
  };

  const handleSliderChange = (event, newValue) => {
    setEventData({
      ...eventData,
      percentage: newValue,
    });
  };

  const games = eventData.games;

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox sx={{ padding: 3 }}>
          <VuiTypography color="error">{error}</VuiTypography>
        </VuiBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox sx={{ padding: 3 }}>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid item xs={12} md={12} xl={12}>
            <EditEventCard
              image={eventImage(games)}
              label={eventData.name}
              title={eventData.name}
              description={games.map(game => game.title).join(', ')}
              owner={eventData.owner_profile}
              games={games}
              event_id={eventData.id}
              event_status={eventData.status}
              actions={[]}
              options={[]}
            />
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <VuiBox mt={3} textAlign="left">
            {eventData.status === "pending" && (
              <>
                <VuiButton
                  size="small"
                  component={Link}
                  href={`/event/${eventData.id}`}
                  disabled={loading}
                  variant="contained"
                  color="info"
                  sx={{ ml: 1 }}
                >
                  {t('view')}
                </VuiButton>
                <VuiButton type="submit" size="small" variant="contained" color="success" disabled={loading} sx={{ ml: 1 }}>
                  {loading ? <CircularProgress size={24} color="inherit" /> : t('saveChanges')}
                </VuiButton>
                <VuiButton
                  onClick={handleSubmitDelete}
                  disabled={loading}
                  size="small"
                  variant="contained"
                  color="error"
                  sx={{ ml: 1 }}
                >
                  {t('delete')}
                </VuiButton>
              </>
            )}
            <VuiBadge
              badgeContent={t('owner')}
              size="lg"
              color="dark"
              container
              sx={{ ml: 1 }}
            />
          </VuiBox>
          <VuiBox my={2}>
            <hr />
          </VuiBox>
          <VuiBox>
            <VuiTypography variant="body1" color="light">
              {t('games')}
            </VuiTypography>
            <Autocomplete
              multiple
              options={searchResults}
              getOptionLabel={(option) => option.title}
              value={selectedGames}
              onChange={(event, newValue) => setSelectedGames(newValue)}
              filterSelectedOptions
              noOptionsText={t('noOptions')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  onChange={(e) => handleSearchChange(e, e.target.value)}
                  placeholder={t('addGames')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    classes: {
                      root: classes.inputRoot,
                    },
                    style: { color: 'white !important' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#001540 !important',
                      color: 'white !important',
                      '& fieldset': {
                        borderColor: '#001540 !important',
                      },
                      '&:hover fieldset': {
                        borderColor: '#001540 !important',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#001540 !important',
                      },
                      '& input::placeholder': {
                        color: 'white !important',
                        opacity: 1,
                      },
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    backgroundColor: '#001540',
                    color: 'white',
                  }}
                >
                  <img src={option.thumbnail || logoNoThumbnail} alt={option.title} style={{ marginRight: 8, width: 75, height: 75, objectFit: 'cover', borderRadius: 4 }} />
                  {option.title}
                </li>
              )}
              PaperComponent={(params) => (
                <VuiBox
                  {...params}
                  sx={{
                    backgroundColor: '#001540',
                    color: 'white',
                  }}
                />
              )}
              ListboxProps={{
                style: {
                  backgroundColor: '#001540',
                  color: 'white',
                },
              }}
            />
          </VuiBox>
          <VuiBox sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('name')}
                </VuiTypography>
                <VuiInput
                  name="name"
                  value={eventData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                  autocomplete="off"
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('eventDate')}
                </VuiTypography>
                <VuiInput
                  type="datetime-local"
                  name="event_date"
                  value={eventData.event_date ? formatDateForInput(eventData.event_date) : ''}
                  onChange={handleChange}
                  fullWidth
                  required
                  sx={{
                    '& input': {
                      color: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#001540',
                      },
                      '&:hover fieldset': {
                        borderColor: '#001540',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#001540',
                      },
                    },
                    '& .MuiInputAdornment-root': {
                      color: 'white',
                    },
                    '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
                      filter: 'invert(1)',  // Cambia el color del icono a blanco
                    },
                    '& input::placeholder': {
                      color: 'white',
                      opacity: 1,
                    },
                  }}
                />
              </VuiBox>
            </div>
            <VuiBox>
              <VuiTypography variant="body1" color="light">
                {t('eventDescription')}
              </VuiTypography>
              <VuiInput
                name="description"
                value={eventData.description}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
                required
                autocomplete="off"
              />
            </VuiBox>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('duration')}
                </VuiTypography>
                <VuiInput
                  name="duration"
                  type="number"
                  value={eventData.duration}
                  onChange={handleChange}
                  fullWidth
                  required
                  inputProps={{
                    min: 10,
                    max: 1800,
                  }}
                  autocomplete="off"
                />
              </VuiBox>    
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('minPlayers')}
                </VuiTypography>
                <VuiInput
                  name="min_players"
                  type="number"
                  value={eventData.min_players}
                  onChange={handleChange}
                  fullWidth
                  required
                  autocomplete="off"
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('maxPlayers')}
                </VuiTypography>
                <VuiInput
                  name="max_players"
                  type="number"
                  value={eventData.max_players}
                  onChange={handleChange}
                  fullWidth
                  required
                  autocomplete="off"
                />
              </VuiBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="subtitle1" color="light">{t('eventType')}</VuiTypography>
                <FormControl fullWidth>
                  <InputLabel className={classes.inputLabel}></InputLabel>
                  <Select
                    name="event_type"
                    value={eventData.event_type}
                    onChange={handleChange}
                    className={classes.select}
                    inputProps={{
                      classes: {
                        root: classes.inputRoot,
                      },
                    }}
                    sx={{
                      cursor: 'pointer !important',
                      backgroundColor: 'rgba(15, 21, 53, 0.87) !important',
                      color: 'white !important',
                      '&:hover': {
                        backgroundColor: 'rgba(15, 21, 53, 0.87) !important',
                      },
                      position: "absolute",
                    }}
                  >
                    {eventTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('languages.title')}
                </VuiTypography>
                <FormControl fullWidth>
                  <InputLabel id="languages-label" />
                  <Select
                    name="languages"
                    multiple
                    value={eventData.languages}
                    onChange={handleLanguageChange}
                    renderValue={(selected) => selected.map((lang) => t(`languages.${lang}`)).join(', ')}
                    className={classes.select}
                    inputProps={{
                      classes: {
                        root: classes.inputRoot,
                      },
                    }}
                    sx={{
                      cursor: 'pointer !important',
                      backgroundColor: 'rgba(15, 21, 53, 0.87) !important',
                      color: 'white !important',
                      '&:hover': {
                        backgroundColor: 'rgba(15, 21, 53, 0.87) !important',
                      },
                      position: "absolute",
                    }}
                  >
                    {languageOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox checked={eventData.languages.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('percentage')}
                </VuiTypography>
                <ThemeProvider theme={sliderTheme}>
                  <Slider
                    value={eventData.percentage}
                    onChange={(e, newValue) => setEventData({ ...eventData, percentage: newValue })}
                    aria-labelledby="percentage-slider"
                    min={0}
                    max={100}
                    step={1}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}%`}
                  />
                </ThemeProvider>
              </VuiBox>
            </div>
            <VuiBox>
              <VuiTypography variant="body1" color="light">
                {t('address')}
              </VuiTypography>
              <VuiInput
                name="address"
                value={eventData.address}
                onChange={handleChange}
                fullWidth
                required
                autocomplete="off"
              />
            </VuiBox>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('city')}
                </VuiTypography>
                <VuiInput
                  name="city"
                  value={eventData.city}
                  onChange={handleChange}
                  fullWidth
                  required
                  autocomplete="off"
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('state')}
                </VuiTypography>
                <VuiInput
                  name="state"
                  value={eventData.state}
                  onChange={handleChange}
                  fullWidth
                  required
                  autocomplete="off"
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('country')}
                </VuiTypography>
                <CountrySelect
                  value={eventData.country}
                  onChange={handleCountryChange}
                />
              </VuiBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('postalCode')}
                </VuiTypography>
                <VuiInput
                  name="postal_code"
                  value={eventData.postal_code}
                  onChange={handleChange}
                  fullWidth
                  required
                  autocomplete="off"
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('visible')}
                </VuiTypography>
                <VuiSwitch
                  color="info"
                  name="visible"
                  checked={eventData.visible}
                  onChange={handleCheckboxChange}
                  label={t('visible')}
                />
              </VuiBox>
              <VuiBox flex="1">
                <VuiTypography variant="body1" color="light">
                  {t('allowGuestNotes')}
                </VuiTypography>
                <VuiSwitch
                  color="info"
                  name="allow_guest_notes"
                  checked={eventData.allow_guest_notes}
                  onChange={handleCheckboxChange}
                  label={t('allowGuestNotes')}
                />
              </VuiBox>
            </div>
          </VuiBox>
        </form>
        <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </VuiBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#1a237e',
            color: 'white !important',
            border: '1px solid #4a148c',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: 'white !important' }}>
          {dialogType === 'edit' ? t('editEvent') : t('deleteEvent')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'white !important' }}>
            {dialogType === 'edit' ? t('confirmEditEvent') : t('confirmDeleteEvent')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleClose} color="light">
            {t('cancel')}
          </VuiButton>
          <VuiButton onClick={dialogType === 'edit' ? handleConfirm : handleDelete} color={dialogType === 'edit' ? 'success' : 'error'}>
            {t('confirm')}
          </VuiButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default EditEventPage;
