import React, { useState, useEffect, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import dataService from "api/data.service";
import MUIDataTable from "mui-datatables";
import { FaStar } from 'react-icons/fa';
import { Box, Typography, CircularProgress, Backdrop } from '@mui/material';
import { ReactComponent as Loading } from "assets/svgs/loading.svg";
import { ReactComponent as NotFound } from "assets/svgs/sad.svg";

// Estilos personalizados para el MUIDataTable
const CustomMUIDataTable = styled(MUIDataTable)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent !important",
    background: "transparent !important",
  },
  "& .MuiTableCell-root": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MuiTableRow-root": {
    backgroundColor: "transparent !important",
    background: "transparent !important",
  },
  "& .MuiTableCell-head": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MuiTableCell-body": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MuiToolbar-root": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MuiTablePagination-root": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MUIDataTable-paper": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MuiTypography-root, &.MuiPaper-elevation, &.MuiPaper-rounded": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MuiTableHead-root": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
    display: "table-row-group",
  },
  "& .MuiTableFooter-root": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MuiTablePagination-root": {
    backgroundColor: "transparent !important",
    color: "white",
    background: "transparent !important",
  },
  "& .MuiTablePagination-input": {
    maxWidth: "65px",
  },
}));

const Data = () => {
  const [tableData, setTableData] = useState({
    rows: [],
    totalCount: 0,
  });
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [page, setPage] = useState(0); // Estado para almacenar la página actual
  const perPage = 10;

  useEffect(() => {
    fetchData(page); // Cargar datos inicialmente con la página actual
  }, [page]); // Dependencia de efecto en `page`

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const data = await dataService.getEvents(page + 1, perPage, 'completed'); // Ajustar la paginación según la API
      const formattedRows = data.resources.map((event) => ({
        [t("games")]: (
          <VuiBox display="flex">
            {event.games.slice(0, 5).map((game, index) => (
              <VuiBox key={index} display="inline-flex" alignItems="center" mr={2}>
                <Link to={`/game/${game.id}`} style={{ textDecoration: "none" }} title={game.title} >
                  {renderGameIcon(game.thumbnail)}
                  {event.games.length === 1 && (
                    <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                      {game.title}
                    </VuiTypography>
                  )}
                </Link>
              </VuiBox>
            ))}
          </VuiBox>
        ),
        [t("name")]: (
          <VuiBox width="8rem" textAlign="left">
            <Link
              to={`/event/${event.event_id}`}
              style={{ textDecoration: "none" }}
            >
              <VuiTypography
                color="white"
                variant="button"
                fontWeight="bold"
              >
                {event.name}
              </VuiTypography>
            </Link>
          </VuiBox>
        ),
        [t("players")]: (
          <VuiBox display="flex" py={1}>
            {avatars(event.players)}
          </VuiBox>
        ),
        [t("date")]: formatDate(event.event_date),
        [t("completion")]: (
          <VuiBox width="8rem" textAlign="left">
            <VuiTypography
              color="white"
              variant="button"
              fontWeight="bold"
            >
              {event.percentage}%
            </VuiTypography>
            <VuiProgress
              value={event.percentage}
              color="info"
              label={false}
              sx={{ background: "#2D2E5F" }}
            />
          </VuiBox>
        ),
        [t("rating")]: (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              {[...Array(5)].map((star, index) => (
                <FaStar
                  key={index}
                  color={index < (event.rating) ? "#ffc107" : "#e4e5e9"}
                />
              ))}
            </Box>
          </Box>
        )
      }));

      setTableData({
        rows: formattedRows,
        totalCount: data.totalCount,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const avatars = (players) =>
    players.map((player, index) => (
      <Tooltip key={index} title={player.display_name} placement="bottom">
        <VuiAvatar
          src={player.avatar}
          alt={player.display_name}
          size="xs"
          sx={{
            border: ({
              borders: { borderWidth },
              palette: { dark },
            }) => `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",
            "&:not(:first-of-type)": {
              ml: -1.25,
            },
            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const renderGameIcon = (thumbnail) => (
    <img
      src={thumbnail}
      alt="Game Thumbnail"
      style={{
       width: "20px",
       height: "20px",
       borderRadius: "50%",
       overflow: "hidden"
      }}
    />
  );

  const formatDate = (date) => {
    const eventDate = new Date(date);
    const year = eventDate.getFullYear();
    const month = String(eventDate.getMonth() + 1).padStart(2, "0");
    const day = String(eventDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const options = {
    responsive: 'standard',
    setTableProps: () => ({ style: { width: '100%' } }),
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    serverSide: true,
    count: tableData.totalCount,
    rowsPerPage: perPage,
    page: page,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: t('noGameData'),
      },
      pagination: {
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('of'),
      },
    },
    onChangePage: (newPage) => setPage(newPage),
  };

  if (loading) {
    return  <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
              <CircularProgress color="inherit" />
            </Backdrop>;
  }

  return (
   <VuiBox flex="1" style={{ width: '100%', overflowX: 'auto' }}>
      <CustomMUIDataTable
        data={tableData.rows}
        columns={[
          { name: t("games"), label: t("games"), options: { filter: false, sort: false } },
          { name: t("name"), label: t("name"), options: { filter: false, sort: false } },
          { name: t("players"), label: t("players"), options: { filter: false, sort: false } },
          { name: t("date"), label: t("date"), options: { filter: false, sort: false } },
          { name: t("completion"), label: t("completion"), options: { filter: false, sort: false } },
          { name: t("rating"), label: t("rating"), options: { filter: false, sort: false } },
        ]}
        options={options}
      />
    </VuiBox>
  );
};

export default Data;