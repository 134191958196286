import React from 'react';
import CoverLayout from "pages/authentication/components/CoverLayout";
import bgSignUp from "assets/images/signUp.jpeg";
import { useTranslation } from "react-i18next";

function PolicyAndPrivacy() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <CoverLayout
      title={t('policyAndPrivacy')}
      color="white"
      description=""
      image={bgSignUp}
      premotto="MeepleFreaks:"
      motto={t('policyAndPrivacy')}
      cardContent
    >
      <div style={{ color: 'white'}}>
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
          {currentLanguage === "es" ? (
            <>
              <h2>Política de Privacidad de Meeplefreaks</h2>
              <h3>1. Propósito y Alcance</h3>
              <p>
                Esta Política de Privacidad establece cómo Meeplefreaks recopila, utiliza, protege y comparte la información personal de sus usuarios. La política se aplica a todos los usuarios registrados de nuestra plataforma.
              </p>
              <h3>2. Información que Recopilamos</h3>
              <p>
                <strong>Datos Personales:</strong> Meeplefreaks recopila y almacena nombres, direcciones de correo electrónico y números de teléfono de los usuarios.
                <br/>
                <strong>Datos de Navegación:</strong> Recopilamos información como direcciones IP, tipo de dispositivo, cookies y otros datos técnicos con el fin de identificar al usuario y mejorar la experiencia de navegación.
                <br/>
                <strong>Datos del Juego:</strong> Almacenamos información relacionada con el progreso de los juegos y estadísticas de los usuarios en la aplicación.
              </p>
              <h3>3. Cómo Usamos la Información</h3>
              <p>
                <strong>Prestación del Servicio:</strong> Utilizamos la información para proporcionar y mejorar los servicios de la aplicación.
                <br/>
                <strong>Comunicaciones:</strong> Podemos enviar actualizaciones, boletines informativos o comunicaciones promocionales a los usuarios por correo electrónico.
                <br/>
                <strong>Análisis y Mejora:</strong> La información recopilada se utiliza para realizar análisis internos y mejorar la experiencia del usuario.
                <br/>
                <strong>Publicidad:</strong> No publicamos ni mostramos publicidad personalizada.
              </p>
              <h3>4. Cómo Compartimos la Información</h3>
              <p>
                <strong>Proveedores de Servicios:</strong> No compartimos información con terceros, excepto con los servicios de Google necesarios para la autenticación.
                <br/>
                <strong>Requerimientos Legales:</strong> Podemos compartir la información del usuario en cumplimiento de la ley, en respuesta a una orden judicial, o para proteger los derechos de la empresa.
                <br/>
                <strong>Transferencias de Negocios:</strong> En caso de una venta o fusión de Meeplefreaks, la información de los usuarios podría ser transferida como parte del negocio.
              </p>
              <h3>5. Derechos de los Usuarios</h3>
              <p>
                <strong>Acceso y Corrección:</strong> Los usuarios pueden acceder y corregir su información personal desde su panel de usuario o solicitándolo a través de soporte@meeplefreaks.com.
                <br/>
                <strong>Eliminación de Datos:</strong> Los usuarios pueden solicitar la eliminación de sus datos directamente desde la aplicación.
                <br/>
                <strong>Oposición y Limitación:</strong> Los usuarios pueden oponerse o limitar el procesamiento de sus datos eliminando su cuenta.
                <br/>
                <strong>Retiro del Consentimiento:</strong> Los usuarios pueden retirar su consentimiento para el uso de sus datos en cualquier momento solicitando la eliminación de sus datos.
              </p>
              <h3>6. Seguridad de la Información</h3>
              <p>
                <strong>Medidas de Seguridad:</strong> Protegemos la información de los usuarios mediante encriptación y controles de acceso robustos.
                <br/>
                <strong>Limitaciones:</strong> Aunque implementamos medidas de seguridad para proteger la información, ninguna transmisión de datos por internet o sistema de almacenamiento es completamente segura.
              </p>
              <h3>7. Cookies y Tecnologías Similares</h3>
              <p>
                <strong>Uso de Cookies:</strong> Utilizamos cookies únicamente para gestionar los inicios de sesión a través de OAUTH.
              </p>
              <h3>8. Retención de Datos</h3>
              <p>
                <strong>Duración:</strong> Conservamos la información personal de los usuarios hasta que la cuenta sea eliminada.
              </p>
              <h3>9. Transferencias Internacionales</h3>
              <p>
                <strong>Declaración:</strong> No realizamos transferencias internacionales de datos personales.
              </p>
              <h3>10. Enlaces a Terceros</h3>
              <p>
                Esta política de privacidad no se aplica a sitios web o servicios de terceros que puedan estar vinculados en nuestra aplicación.
              </p>
              <h3>11. Cambios en la Política de Privacidad</h3>
              <p>
                <strong>Actualizaciones:</strong> Podremos actualizar esta política de privacidad. Los usuarios serán responsables de revisarla periódicamente para estar al tanto de los cambios.
                <br/>
                <strong>Fecha de la Última Revisión:</strong> 21/08/2024.
              </p>
              <h3>12. Contacto</h3>
              <p>
                <strong>Información de Contacto:</strong> Si tienes preguntas o inquietudes sobre esta política de privacidad, puedes contactarnos en soporte@meeplefreaks.com.
              </p>
              <h3>13. Consentimiento</h3>
              <p>
                <strong>Aceptación de la Política:</strong> Al utilizar la aplicación Meeplefreaks, los usuarios aceptan los términos de esta política de privacidad.
              </p>
            </>
          ) : (
            <>
              <h2>Meeplefreaks Privacy Policy</h2>
              <h3>1. Purpose and Scope</h3>
              <p>
                This Privacy Policy outlines how Meeplefreaks collects, uses, protects, and shares the personal information of its users. The policy applies to all registered users of our platform.
              </p>
              <h3>2. Information We Collect</h3>
              <p>
                <strong>Personal Data:</strong> Meeplefreaks collects and stores users' names, email addresses, and phone numbers.
                <br/>
                <strong>Browsing Data:</strong> We collect information such as IP addresses, device types, cookies, and other technical data to identify users and enhance their browsing experience.
                <br/>
                <strong>Game Data:</strong> We store information related to users' game progress and statistics within the application.
              </p>
              <h3>3. How We Use the Information</h3>
              <p>
                <strong>Service Provision:</strong> We use the information to provide and improve the services of the application.
                <br/>
                <strong>Communications:</strong> We may send updates, newsletters, or promotional communications to users via email.
                <br/>
                <strong>Analysis and Improvement:</strong> The information collected is used for internal analysis and to enhance user experience.
                <br/>
                <strong>Advertising:</strong> We do not publish or display personalized advertising.
              </p>
              <h3>4. How We Share the Information</h3>
              <p>
                <strong>Service Providers:</strong> We do not share information with third parties, except for Google's services required for authentication.
                <br/>
                <strong>Legal Requirements:</strong> We may share user information in compliance with the law, in response to a court order, or to protect the company's rights.
                <br/>
                <strong>Business Transfers:</strong> In the event of a sale or merger of Meeplefreaks, user information may be transferred as part of the business.
              </p>
              <h3>5. User Rights</h3>
              <p>
                <strong>Access and Correction:</strong> Users can access and correct their personal information through their user panel or by contacting us at soporte@meeplefreaks.com.
                <br/>
                <strong>Data Deletion:</strong> Users can request the deletion of their data directly through the application.
                <br/>
                <strong>Objection and Limitation:</strong> Users can object to or limit the processing of their data by deleting their account.
                <br/>
                <strong>Withdrawal of Consent:</strong> Users can withdraw their consent to the use of their data at any time by requesting the deletion of their data.
              </p>
              <h3>6. Information Security</h3>
              <p>
                <strong>Security Measures:</strong> We protect users' information through encryption and robust access controls.
                <br/>
                <strong>Limitations:</strong> While we implement security measures to protect information, no data transmission over the internet or storage system is completely secure.
              </p>
              <h3>7. Cookies and Similar Technologies</h3>
              <p>
                <strong>Use of Cookies:</strong> We use cookies solely to manage logins through OAUTH.
              </p>
              <h3>8. Data Retention</h3>
              <p>
                <strong>Duration:</strong> We retain users' personal information until the account is deleted.
              </p>
              <h3>9. International Transfers</h3>
              <p>
                <strong>Statement:</strong> We do not perform international transfers of personal data.
              </p>
              <h3>10. Links to Third Parties</h3>
              <p>
                This privacy policy does not apply to third-party websites or services that may be linked within our application.
              </p>
              <h3>11. Changes to the Privacy Policy</h3>
              <p>
                <strong>Updates:</strong> We may update this privacy policy. Users are responsible for periodically reviewing it to stay informed of changes.
                <br/>
                <strong>Last Reviewed Date:</strong> 21/08/2024.
              </p>
              <h3>12. Contact</h3>
              <p>
                <strong>Contact Information:</strong> If you have questions or concerns about this privacy policy, you can contact us at soporte@meeplefreaks.com.
              </p>
              <h3>13. Consent</h3>
              <p>
                <strong>Policy Acceptance:</strong> By using the Meeplefreaks application, users agree to the terms of this privacy policy.
              </p>
            </>
          )}
        </div>
      </div>
    </CoverLayout>
  );
}

export default PolicyAndPrivacy;