import React, { useState, useEffect } from 'react';
import VuiButton from "components/VuiButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import dataService from 'api/data.service';

const JoinEventButton = ({ event_id, initialJoinStatus }) => {
  const { t } = useTranslation();
  const [isJoining, setIsJoining] = useState(false);
  const [joinStatus, setJoinStatus] = useState(initialJoinStatus);

  const handleJoinEvent = async () => {
    if (joinStatus === 'rejected' || joinStatus === 'not_attended') {
      return;
    }
    setIsJoining(true);
    try {
      const joining = joinStatus !== 'accepted' && joinStatus !== 'pending';
      const data = await dataService.joinEvent(event_id, joining);
      if (data.status) {
        setJoinStatus(joining ? 'accepted' : 'not_joined');
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsJoining(false);
    }
  };

  useEffect(() => {
    setJoinStatus(initialJoinStatus);
  }, [initialJoinStatus]);

  const getButtonConfig = (status) => {
    switch (status) {
      case 'accepted':
        return { text: t('leaveEvent'), color: 'error', disabled: false };
      case 'pending':
        return { text: t('pendingEvent'), color: 'warning', disabled: true };
      case 'rejected':
        return { text: t('rejectedEvent'), color: 'warning', disabled: true };
      case 'not_attended':
        return { text: t('notAttended'), color: 'warning', disabled: true };
      default:
        return { text: t('joinEvent'), color: 'primary', disabled: false };
    }
  };

  const { text, color, disabled } = getButtonConfig(joinStatus);

  return (
    <Tooltip title={text}>
      <VuiButton
        variant="contained"
        color={color}
        startIcon={<AddCircleOutlineIcon fontSize="small" />}
        onClick={handleJoinEvent}
        size="small"
        sx={{ minWidth: 'unset', ml: 2 }}
        disabled={isJoining || disabled}
      >
        {text}
      </VuiButton>
    </Tooltip>
  );
};

export default JoinEventButton;
