import React, { useState } from 'react';
import CoverLayout from "pages/authentication/components/CoverLayout";
import bgSignUp from "assets/images/signUp.jpeg";
import { useTranslation } from "react-i18next";
import VuiInput from 'components/VuiInput';
import VuiButton from 'components/VuiButton';
import VuiBox from 'components/VuiBox';
import loginService from "api/login.service";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [captchaValue, setCaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isWeb, setIsWeb] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || '';
    if (userAgent.includes('Mobile')) {
      setIsWeb(false);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (isWeb && !captchaValue) {
        throw new Error(t("captchaError"));
      }
      const data = await loginService.forgotPassword(email, captchaValue, isWeb);
      setMessage(t('recoverMailSent'));
    } catch (error) {
      setMessage(t('errorSendingEmail'));
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  if (loading) {
    return (
      <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <CoverLayout
      title={t('forgotPassword')}
      color="white"
      description=""
      image={bgSignUp}
      premotto="MeepleFreaks:"
      motto={t('forgotPassword')}
      cardContent
    >
      <div style={{ color: 'white'}}>
        <form onSubmit={handleSubmit}>
          <VuiInput
            autocomplete="off"
            type="email"
            placeholder={t('email')}
            value={email}
            sx={{ color: 'white !important' }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br/>
          <VuiButton type="submit" variant="contained" color="primary">{t('sendResetLink')}</VuiButton>
          {isWeb && (
            <VuiBox mt={3}>
              <ReCAPTCHA
                sitekey="6LeLsSkqAAAAAKYxsxHgrhCLT01xKoOjdITSOtAA"
                onChange={handleCaptchaChange}
              />
            </VuiBox>
          )}
        </form>
        {message && <p>{message}</p>}
      </div>
    </CoverLayout>
  );
};

export default ForgotPassword;