import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dataService from 'api/data.service';

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import DashboardLayout from "utils/LayoutContainers/DashboardLayout";
import Footer from "utils/Footer";
import ProfileInfoCard from "utils/Cards/InfoCards/ProfileInfoCard";
import Header from "pages/profile/components/Header";

// React icons
import { IoGameController, IoStatsChart, IoPeople, IoCash } from "react-icons/io5";
import MiniStatisticsCard from "utils/Cards/StatisticsCards/MiniStatisticsCard";
import GameRating from "pages/profile/components/GameRating";
import LastEvents from "pages/profile/components/LastEvents";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from '@mui/icons-material/YouTube';

function Overview() {
  const { t } = useTranslation();
  const [profile, setProfile] = useState(null);
  const [money, setMoney] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Icon mapping for platforms
  const platformIcons = {
    facebook: <FacebookIcon />,
    instagram: <InstagramIcon />,
    X: <TwitterIcon />,
    youtube: <YouTubeIcon />,
  };

  // Function to get social links
  const getSocialLinks = (socialLinks) => {
    return socialLinks.map(link => {
      return {
        link: link.url,
        icon: platformIcons[link.platform] || null,
        color: "white",
      };
    });
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await dataService.getSessionProfile();
        setProfile(data.resource);
        const money = await dataService.giveMeTheMoney();
        setMoney(money.resource)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <DashboardLayout>
      <Header />
      <VuiBox mt={5} mb={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: t('totalGames'), fontWeight: "regular" }}
                count={profile.collection_count}
                icon={{ color: "info", component: <IoGameController size="22px" color="white" /> }}
                href="/collection"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: t('gamesPlayed') }}
                count={profile.events_count}
                icon={{ color: "info", component: <IoStatsChart size="22px" color="white" /> }}
                href="/played-games"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: t('yourFriends') }}
                count={profile.friends_count}
                icon={{ color: "info", component: <IoPeople size="22px" color="white" /> }}
                href="/my-people"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: t('gamesValue') }}
                count={`${money}€`}
                icon={{ color: "info", component: <IoCash size="22px" color="white" /> }}
                href="/collection"
              />
            </Grid>
          </Grid>
        </VuiBox>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid
            item
            xs={12}
            xl={3}
            xxl={3}
            sx={({ breakpoints }) => ({
              [breakpoints.only("xl")]: {
                gridArea: "1 / 2 / 2 / 3",
              },
            })}
          >
            <ProfileInfoCard
              title={t('profileInformation')}
              description={profile.about_me}
              info={{
                [t('fullName')]: profile.display_name,
                [t('phone')]: profile.phone,
                [t('email')]: profile.email,
                [t('country')]: profile.country,
                [t('city')]: profile.city,
                [t('birthday')]: profile.birthday,
              }}
              social={getSocialLinks(profile.social)}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <GameRating />
          </Grid>
          <Grid item xs={12} lg={6} xl={5}>
            <LastEvents />
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
