// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from 'locales/en/translation.json';
import esTranslation from 'locales/es/translation.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Idioma por defecto
    interpolation: {
      escapeValue: false, // React ya escapa los valores por defecto
    },
  });

export default i18n;