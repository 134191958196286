import { useState, useEffect, useMemo, useContext } from "react";
import { AuthProvider, AuthContext } from "context/AuthContext";
import { Route, Switch, Redirect, useLocation, BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";

import VuiBox from "components/VuiBox";
import Sidenav from "utils/Sidenav";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import { getTranslatedRoutes } from "routes";
import { useTranslation } from "react-i18next";
import { useVisionUIController, setMiniSidenav } from "context";
import SignIn from "pages/authentication/sign-in";
import SignUp from "pages/authentication/sign-up";
import Dashboard from "pages/dashboard";
import Profile from "pages/profile";
// New pages
import Collection from "pages/collection";
import Wishlist from "pages/wishlist";
import Games from "pages/games";
import GamePage from "pages/game_page"
import PlayedGames from "pages/played_games";
import UpcomingGames from "pages/upcoming_games";
import User from 'pages/user';
import Event from 'pages/event';

const AppContent = () => {
  const { t } = useTranslation();
  const routes = getTranslatedRoutes(t);
  const { user } = useContext(AuthContext);
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const storedData = localStorage.getItem('currentUser');
  let currentData = JSON.parse(storedData);
  // Verifica si los datos han expirado comparando la propiedad `exp`
  if (currentData && currentData.exp && currentData.exp * 1000 < Date.now()) {
    // Borra los datos almacenados si han expirado
    localStorage.removeItem('currentUser');
    currentData = null
  }

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => {
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

const getRoutes = (allRoutes, currentData) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse, currentData);
    }

    if (route.route) {
      if (route.private) {
        return (
          <Route
            key={route.key}
            exact
            path={route.route}
            render={(props) =>
              currentData && currentData['user_id'] ? (
                <route.component {...props} />
              ) : (
                <Redirect to="/authentication/sign-in" />
              )
            }
          />
        );
      } else {
        if ((!currentData || !currentData['user_id']) && !["/authentication/sign-in", "/authentication/sign-up", "/terms-and-conditions", "/policy-and-privacy", "/authentication/forgot-password", "/authentication/reset-password"].includes(route.route)) {
          return <Redirect to="/authentication/sign-in" />;
        } else {
          return <Route exact path={route.route} component={route.component} key={route.key} />;
        }
      }
    }

    return null;
  });

  const configsButton = (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="info"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </VuiBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand=""
              brandName="MeepleFreaks"
              routes={getTranslatedRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Switch>
          {getRoutes(getTranslatedRoutes, currentData)}
          <PrivateRoute path="/game/:id" user={currentData} component={GamePage} />
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand=""
            brandName="MeepleFreaks"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Switch>
        {getRoutes(routes, currentData)}
        <PrivateRoute path="/game/:id" user={currentData} component={GamePage} />
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </ThemeProvider>
  );
};

const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      user ? (
        <Component {...props} />
      ) : (
        <Redirect to="/authentication/sign-in" />
      )
    }
  />
);

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </AuthProvider>
  );
}
