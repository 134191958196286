import React from 'react';
import { Grid } from '@mui/material';
import DashboardLayout from 'utils/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'utils/Navbars/DashboardNavbar';
import Footer from 'utils/Footer';
import WelcomeMark from 'pages/dashboard/components/WelcomeMark';
import AnnouncementsList from 'pages/dashboard/components/AnnouncementsList';

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} xl={12}>
          <WelcomeMark />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <AnnouncementsList />
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
