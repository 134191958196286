import React from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiAvatar from "components/VuiAvatar";
import { usernameanking } from "utils/Rankings/context";
import { rankingItem, rankingItemIcon } from "utils/Rankings/RankingItem/styles";

function RankingItem({ color="info", icon, username, avatar, description = "", badges = [], score, lastItem = false}) {

  const renderBadges = 
    badges.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`;
          return (
            <VuiBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              <VuiBadge color={color} size="xs" badgeContent={badge} container />
            </VuiBox>
          );
        })
      : null;

  return (
    <VuiBox position="relative" mb="24px" sx={(theme) => rankingItem(theme, { lastItem })}>
      <VuiBox
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="-8px"
        zIndex={2}
      >
        {icon}
      </VuiBox>
      <VuiBox ml="30px" pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <VuiBox display="flex" alignItems="center" mb={1}>
          <VuiAvatar
            src={avatar}
            alt={username}
            size="xs"
            sx={({ borders: { borderWidth }, palette: { dark }, functions: { rgba } }) => ({
              border: `${borderWidth[2]} solid ${rgba(dark.focus, 0.5)}`,
              cursor: "pointer",
              position: "relative",
              ml: -1.25,
              "&:hover, &:focus": {
                zIndex: "10",
              },
            })}
          />
          <VuiTypography variant="button" fontWeight="medium" color="white" ml={1}>
            {username}
            {score !== 0 ? (
              <VuiBadge sx={{marginLeft: 2}} color="light" size="lg" badgeContent={score} container max="99999" />
            ) : null}
          </VuiTypography>
        </VuiBox>
        {description ? (
          <VuiBox mt={2} mb={1.5}>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              {description}
            </VuiTypography>
          </VuiBox>
        ) : null}
        {badges.length > 0 ? (
          <VuiBox display="flex" pb={lastItem ? 1 : 2}>
            {renderBadges}
          </VuiBox>
        ) : null}
      </VuiBox>
    </VuiBox>
  );
}

RankingItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  description: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool,
};

export default RankingItem;
