import React, { useState, useEffect } from 'react';
import CoverLayout from "pages/authentication/components/CoverLayout";
import bgSignUp from "assets/images/signUp.jpeg";
import { useTranslation } from "react-i18next";
import VuiInput from 'components/VuiInput';
import VuiButton from 'components/VuiButton';
import VuiBox from 'components/VuiBox';
import loginService from "api/login.service";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation, useHistory } from 'react-router-dom';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [captchaValue, setCaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [isWeb, setIsWeb] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || '';
    if (userAgent.includes('Mobile')) {
      setIsWeb(false);
    }
  }, []);

  useEffect(() => {
    if (!token) {
      history.push('/authentication/sign-in');
    }
  }, [token, history]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (isWeb && !captchaValue) {
        throw new Error(t("captchaError"));
      }
      const data = await loginService.resetPassword(token, password, captchaValue, isWeb);
      setMessage(t('updatedPassword'));
      history.push('/authentication/sign-in');
    } catch (error) {
      setMessage(t('errorResettingPassword'));
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  if (loading) {
    return (
      <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <CoverLayout
      title={t('resetPassword')}
      color="white"
      description=""
      image={bgSignUp}
      premotto="MeepleFreaks:"
      motto={t('resetPassword')}
      cardContent
    >
      <div>
        <form onSubmit={handleSubmit}>
          <VuiInput
            autocomplete="off"
            type="new-password"
            placeholder={t('enterNewPassword')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ color: 'white !important' }}
          />
          <br/>
          <VuiButton type="submit" variant="contained" color="error">{t('resetPassword')}</VuiButton>
          {isWeb && (
            <VuiBox mt={3}>
              <ReCAPTCHA
                sitekey="6LeLsSkqAAAAAKYxsxHgrhCLT01xKoOjdITSOtAA"
                onChange={handleCaptchaChange}
              />
            </VuiBox>
          )}
        </form>
        {message && <p>{message}</p>}
      </div>
    </CoverLayout>
  );
};

export default ResetPassword;