import React, { useEffect, useState } from 'react';
import CoverLayout from "pages/authentication/components/CoverLayout";
import bgSignUp from "assets/images/signUp.jpeg";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <CoverLayout
      title={t('termsAndConditions')}
      color="white"
      description=""
      image={bgSignUp}
      premotto="MeepleFreaks:"
      motto={t('termsAndConditions')}
      cardContent
    >
      <div style={{ color: 'white'}}>
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
          {currentLanguage === "es" ? (
            <>
              <p>
                Bienvenido a nuestra aplicación. Si continúa navegando y utilizando este
                sitio web, está aceptando cumplir y estar sujeto a los siguientes
                términos y condiciones de uso, que junto con nuestra política de
                privacidad rigen la relación con este sitio web.
              </p>
              <h2>Uso comercial</h2>
              <p>
                Todos los servicios ofrecidos en esta web son gratuitos y no pueden ser
                utilizados con fines comerciales
              </p>
              <h2>Edad Mínima de Uso</h2>
              <p>
                Al utilizar nuestros servicios, declaras que tienes al menos 18 años de edad.
                Nuestros servicios están destinados únicamente a usuarios que cumplen con este requisito de edad.
                Si tienes menos de 18 años, no se te permite acceder a, ni utilizar, nuestros servicios
                bajo ninguna circunstancia.
                Nos reservamos el derecho de solicitar una prueba de edad en cualquier momento para verificar
                que los usuarios cumplen con este requisito.
                Si descubrimos que un usuario menor de 18 años está utilizando nuestros servicios,
                nos reservamos el derecho de suspender o cancelar su cuenta inmediatamente, sin previo aviso.
              </p>
              <h2>Uso del Sitio</h2>
              <p>
                El uso de este sitio web está sujeto a las siguientes condiciones de
                uso: El contenido de las páginas de este sitio web es para su
                información general y uso exclusivo. Está sujeto a cambios sin previo
                aviso.
              </p>
              <h2>Enlaces a Otros Sitios Web</h2>
              <p>
                Nuestro sitio web puede contener enlaces a otros sitios web de interés.
                Sin embargo, una vez que haya utilizado estos enlaces para salir de
                nuestro sitio, debe tener en cuenta que no tenemos control sobre ese
                otros sitio web.
              </p>
              <h2>Limitación de Responsabilidad</h2>
              <p>
                En ningún caso seremos responsables de ninguna pérdida o daño, incluidos
                entre otros, pérdidas o daños indirectos o consecuentes, o cualquier
                pérdida o daño que surja de la pérdida de datos o beneficios que surja
                de, o en conexión con, el uso de este sitio web.
              </p>
              <p>
                Algunos de los datos presentados en esta aplicación provienen de BoardGameGeek (BGG).
                BGG es el propietario de los datos originales, y estos se utilizan de acuerdo con los términos y condiciones proporcionados por BoardGameGeek.
                Para más información sobre sus términos, visite [el sitio web de BoardGameGeek](https://boardgamegeek.com/).
              </p>
              <p>
                No garantizamos la exactitud, integridad o actualidad de la información obtenida de BoardGameGeek
                y presentada en esta aplicación. La información puede estar sujeta a cambios,
                y no somos responsables de ninguna inexactitud o desactualización. 
                Los datos obtenidos de BoardGameGeek se utilizan exclusivamente para proporcionar información sobre juegos de mesa
                en esta aplicación. Cualquier otro uso de estos datos puede estar sujeto a restricciones
                y es responsabilidad del usuario
                verificar la licencia y los términos de uso de BoardGameGeek.
                Todos los derechos sobre los datos obtenidos de BoardGameGeek son propiedad exclusiva de BoardGameGeek.
                No está permitido redistribuir, vender o utilizar estos datos para propósitos comerciales
                sin el permiso explícito de BoardGameGeek.
                Para más información sobre las políticas de privacidad y los términos de uso de BoardGameGeek,
                por favor visite su [política de privacidad](https://boardgamegeek.com/privacy) 
                y [términos de uso](https://boardgamegeek.com/terms).
                Esta aplicación y los datos proporcionados por terceros, incluidos los obtenidos de BoardGameGeek,
                se proporcionan "tal como están" sin ninguna garantía de ningún tipo, ya sea explícita o implícita. 
                No asumimos ninguna responsabilidad por cualquier daño que pueda surgir del uso de estos datos.
                Nos reservamos el derecho de actualizar estos términos y condiciones en cualquier momento para reflejar cambios
                en las políticas de uso de datos o por otras razones.
                Se recomienda revisar esta sección periódicamente para estar informado sobre cualquier cambio.
              </p>
              <h2>Sección de Términos y Condiciones: Exclusión de Responsabilidad en Eventos</h2>
              <p>
                Exclusión de Responsabilidad en Eventos
                En MeepleFreaks, nos esforzamos por ofrecer una plataforma segura y agradable para todos nuestros usuarios. Sin embargo, es importante que comprendas y aceptes que no nos hacemos responsables de los eventos ni de cualquier incidente, daño o pérdida que pueda ocurrir durante o en relación con los eventos organizados a través de nuestra plataforma.
              </p>
              <p>
                **1. Responsabilidad sobre Eventos:
                Toda la información proporcionada sobre los eventos, incluyendo pero no limitándose a horarios, ubicaciones y detalles del evento, es proporcionada de buena fe. No garantizamos que esta información sea siempre precisa, actualizada o completa.
              </p>
              <p>
                **2. Riesgos Asociados:
                Los usuarios asumen todos los riesgos asociados con la participación en eventos. No seremos responsables de ningún daño, lesión, pérdida o gasto que pueda surgir durante o como resultado de la participación en dichos eventos.
              </p>
              <p>
                **3. Conducta de los Participantes:
                Los participantes en los eventos son responsables de su propia conducta. Cualquier disputa o problema que surja entre los participantes debe ser resuelto directamente entre ellos, sin implicar nuestra responsabilidad.
              </p>
              <p>
                **4. Modificaciones y Cancelaciones:
                Nos reservamos el derecho de modificar, cancelar o reprogramar eventos según sea necesario. En tales casos, haremos todo lo posible por notificar a los participantes con la mayor antelación posible, pero no nos responsabilizamos por ninguna pérdida o inconveniente resultante de dichos cambios.
              </p>
              <p>
                **5. Limitación de Responsabilidad:
                En la medida permitida por la ley, excluimos toda responsabilidad por daños indirectos, especiales, incidentales o consecuentes que puedan surgir en relación con los eventos, incluso si hemos sido informados de la posibilidad de tales daños.
              </p>
              <p>
                Al utilizar nuestra plataforma y participar en eventos organizados a través de ella, aceptas estos términos y entiendes que tu participación es bajo tu propio riesgo. Si tienes alguna pregunta o inquietud sobre estos términos, no dudes en ponerte en contacto con nosotros para obtener más información.
              </p>
            </>
          ) : (
            <>
              <p>
                Welcome to our application. If you continue browsing and using this
                website, you are agreeing to comply with and be bound by the following
                terms and conditions of use, which together with our privacy policy
                Privacy govern your relationship with this website.
              </p>
              <h2>Commercial use</h2>
              <p>
                All services offered on this website are free and cannot be
                used for commercial purposes
              </p>
              <h2>Minimum Age Requirement</h2>
              <p>
                By using our services, you confirm that you are at least 18 years of age.
                Our services are intended solely for users who meet this age requirement.
                If you are under 18, you are not permitted to access or use our services under any circumstances.
                We reserve the right to request proof of age at any time to verify that users meet this requirement.
                If we discover that a user under the age of 18 is using our services, we reserve the right
                to suspend or terminate their account immediately, without prior notice.
              </p>
              <h2>Use of the Site</h2>
              <p>
                The use of this website is subject to the following conditions of
                Use: The content of the pages of this website is for your use.
                general information and exclusive use. It is subject to change without prior notice
                warning.
              </p>
              <h2>Links to Other Websites</h2>
              <p>
                Our website may contain links to other websites of interest.
                However, once you have used these links to exit
                our site, you should note that we have no control over that
                other website.
              </p>
              <h2>Limitation of Liability</h2>
              <p>
                Under no circumstances will we be liable for any loss or damage, including
                among others, indirect or consequential loss or damage, or any
                loss or damage arising from loss of data or profits arising
                of, or in connection with, the use of this website.
              </p>
              <p>
                Some of the data presented in this app comes from BoardGameGeek (BGG).
                BGG is the owner of the original data, and the data is used in accordance with the terms and conditions provided by BoardGameGeek.
                For more information on its terms, visit [BoardGameGeek's website](https://boardgamegeek.com/).
              </p>
              <p>
                We do not guarantee the accuracy, completeness or timeliness of any information obtained from BoardGameGeek.
                and presented in this application. The information may be subject to change,
                and we are not responsible for any inaccuracies or out-of-dateness. 
                Data obtained from BoardGameGeek is used exclusively to provide information about board games
                in this application. Any other use of this data may be subject to restrictions
                and it is the responsibility of the user
                check BoardGameGeek's license and terms of use.
                All rights to data obtained from BoardGameGeek are the exclusive property of BoardGameGeek.
                You are not allowed to redistribute, sell or use this data for commercial purposes
                without the explicit permission of BoardGameGeek.
                For more information about BoardGameGeek's privacy policies and terms of use,
                please visit their [privacy policy](https://boardgamegeek.com/privacy) 
                and [terms of use](https://boardgamegeek.com/terms).
                This application and data provided by third parties, including those obtained from BoardGameGeek,
                They are provided "as is" without any warranty of any kind, either express or implied. 
                We assume no responsibility for any damage that may arise from the use of this data.
                We reserve the right to update these terms and conditions at any time to reflect changes.
                in data use policies or for other reasons.
                It is recommended that you review this section periodically to be informed of any changes.
              </p>
              <h2>Event Liability Disclaimer</h2>
              <p>
                At MeepleFreaks, we strive to provide a safe and enjoyable platform for all our users. However, it is important that you understand and accept that we are not responsible for the events and any incidents, damages, or losses that may occur during or in relation to events organized through our platform.
              </p>
              <p>
                **1. Event Responsibility:
                All information provided about events, including but not limited to schedules, locations, and event details, is provided in good faith. We do not guarantee that this information is always accurate, up-to-date, or complete.
              </p>
              <p>
                **2. Associated Risks:
                Users assume all risks associated with participating in events. We are not responsible for any damage, injury, loss, or expense that may arise during or as a result of participating in such events.
              </p>
              <p>
                **3. Participant Conduct:
                Participants in events are responsible for their own conduct. Any disputes or issues arising between participants should be resolved directly between them, without implicating our responsibility.
              </p>
              <p>
                **4. Modifications and Cancellations:
                We reserve the right to modify, cancel, or reschedule events as necessary. In such cases, we will make every effort to notify participants as far in advance as possible, but we are not responsible for any loss or inconvenience resulting from such changes.
              </p>
              <p>
                **5. Limitation of Liability:
                To the extent permitted by law, we exclude all liability for indirect, special, incidental, or consequential damages that may arise in connection with the events, even if we have been advised of the possibility of such damages.
              </p>
              <p>
                By using our platform and participating in events organized through it, you agree to these terms and understand that your participation is at your own risk. If you have any questions or concerns about these terms, please feel free to contact us for more information.
              </p>
            </>
          )}
        </div>
      </div>
    </CoverLayout>
  );
}

export default TermsAndConditions;
