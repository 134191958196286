import Dashboard from "pages/dashboard";
import Profile from "pages/profile";
import SignIn from "pages/authentication/sign-in";
import SignUp from "pages/authentication/sign-up";
// New pages
import Collection from "pages/collection";
import Wishlist from "pages/wishlist";
import Games from "pages/games";
import GamePage from "pages/game_page";
import PlayedGames from "pages/played_games";
import Event from "pages/event";
import UpcomingGames from "pages/upcoming_games";
import MyPeople from "pages/my_people";
import CreateEvent from "pages/create_event"
import EditEvent from "pages/edit_event"
import User from "pages/user"
import UserCollection from "pages/user_collection";
import UserWishlist from "pages/user_wishlist";
import EventScores from "pages/event_scores"
import Configurations from "pages/configurations"
import TermsAndConditions from "pages/terms_and_conditions";
import PolicyAndPrivacy from "pages/policy_and_privacy";
import ForgotPassword from "pages/authentication/forgot_password";
import ResetPassword from "pages/authentication/reset_password";
// Vision UI Dashboard React icons
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { MdPeople, MdGames, MdHistory, MdCollections, MdEvent } from "react-icons/md";
import { AiFillHeart } from "react-icons/ai";
import { IoAdd } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";

const baseRoutes = [
  {
    type: "collapse",
    key: "dashboard",
    name: "dashboard",
    route: "/dashboard",
    private: true,
    icon: <IoHome size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
  },
  { type: "title", key: "account-pages", private: true },
  {
    type: "collapse",
    key: "profile",
    name: "profile",
    route: "/profile",
    private: true,
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: Profile,
    noCollapse: true,
  },
  {
    type: "collapse",
    key: "signIn",
    private: false,
    route: "/authentication/sign-in",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: "collapse",
    key: "signUp",
    private: false,
    route: "/authentication/sign-up",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
  },
  {
    type: "collapse",
    key: "termsAndConditions",
    private: false,
    route: "/terms-and-conditions",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: TermsAndConditions,
    noCollapse: true,
  },
  {
    type: "collapse",
    key: "policyAndPrivacy",
    private: false,
    route: "/policy-and-privacy",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: PolicyAndPrivacy,
    noCollapse: true,
  },
  {
    type: "collapse",
    key: "forgotPassword",
    private: false,
    route: "/authentication/forgot-password",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: ForgotPassword,
    noCollapse: true,
  },
  {
    type: "collapse",
    key: "resetPassword",
    private: false,
    route: "/authentication/reset-password",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: ResetPassword,
    noCollapse: true,
  },
  {
    type: "collapse",
    key: "games",
    name: "discoverGames",
    route: "/games",
    private: true,
    icon: <MdGames size="15px" color="inherit" />,
    component: Games,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "my-people",
    key: "my-people",
    private: true,
    route: "/my-people",
    icon: <MdPeople size="15px" color="inherit" />,
    component: MyPeople,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "myCollection",
    key: "collection",
    private: true,
    route: "/collection",
    icon: <MdCollections size="15px" color="inherit" />,
    component: Collection,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "wishlist",
    key: "wishlist",
    private: true,
    route: "/wishlist",
    icon: <AiFillHeart size="15px" color="inherit" />,
    component: Wishlist,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "upcoming-games",
    key: "upcoming-games",
    private: true,
    route: "/upcoming-games",
    icon: <MdEvent size="15px" color="inherit" />,
    component: UpcomingGames,
    noCollapse: true,
  },
  {
    type: "collapse",
    key: "played-games",
    name: "played-games",
    private: true,
    route: "/played-games",
    icon: <MdHistory size="15px" color="inherit" />,
    component: PlayedGames,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "create-event",
    key: "create-event",
    private: true,
    route: "/create-event",
    icon: <IoAdd size="15px" color="white"/>,
    component: CreateEvent,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "configurations",
    key: "configurations",
    private: true,
    route: "/configurations",
    icon: <IoSettings size="15px" color="inherit" />,
    component: Configurations,
    noCollapse: true,
  },
  {
    type: "route",
    name: "event",
    key: "event",
    private: true,
    route: "/event/:id",
    icon: <MdEvent size="15px" color="inherit" />,
    component: Event,
    noCollapse: true,
  },
  {
    type: "route",
    name: "gamePage",
    key: "gamePage",
    private: true,
    route: "/game/:id",
    icon: <MdGames size="15px" color="inherit" />,
    component: GamePage,
    noCollapse: true,
  },
  {
    type: "route",
    name: "edit-event",
    key: "edit-event",
    private: true,
    route: "/edit-event/:id",
    icon: <MdEvent size="15px" color="inherit" />,
    component: EditEvent,
    noCollapse: true,
  },
  {
    type: "route",
    name: "event-scores",
    key: "event-scores",
    private: true,
    route: "/event-scores/:id",
    icon: <MdEvent size="15px" color="inherit" />,
    component: EventScores,
    noCollapse: true,
  },
  {
    type: "route",
    name: "user",
    key: "user",
    private: true,
    route: "/user/:id",
    icon: <MdGames size="15px" color="inherit" />,
    component: User,
    noCollapse: true,
  },
  {
    type: "route",
    name: "userCollection",
    key: "userCollection",
    private: true,
    route: "/user-collection/:id",
    icon: <MdCollections size="15px" color="inherit" />,
    component: UserCollection,
    noCollapse: true,
  },
  {
    type: "route",
    name: "userWishlist",
    key: "userWishlist",
    private: true,
    route: "/user-wishlist/:id",
    icon: <AiFillHeart size="15px" color="inherit" />,
    component: UserWishlist,
    noCollapse: true,
  },
];

export const getTranslatedRoutes = (t) => {
  const filteredRoutes = baseRoutes.filter(route => route.route !== "/game/:id" || route.route !== "/event/:id" || route.route !== "/user/:id" || route.route !== "/edit-event/:id"  || route.route !== "/event-scores/:id");
  return filteredRoutes.map(route => {
    if (route.type === "title") {
      return { ...route, title: "MeepleFreaks" };
    }
    return { ...route, name: t(`routes.${route.name}`) };
  });
};