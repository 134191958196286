// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Ranking context
import { RankingProvider } from "utils/Rankings/context";

function RankingList({ title, children }) {
  return (
    <RankingProvider>
      <Card>
        <VuiBox>
          <VuiBox pt={3} px={3}>
            <VuiTypography variant="h5" fontWeight="medium" color="light">
              {title}
            </VuiTypography>
          </VuiBox>
          <VuiBox p={2}>{children}</VuiBox>
        </VuiBox>
      </Card>
    </RankingProvider>
  );
}

// Typechecking props for the RankingList
RankingList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default RankingList;
