import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import DashboardLayout from 'utils/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'utils/Navbars/DashboardNavbar';
import Footer from 'utils/Footer';
import FriendInfoCard from 'utils/Cards/InfoCards/FriendInfoCard';
import VuiInput from "components/VuiInput";
import FriendRequestButton from 'components/CustomButtons/FriendRequestButton'; 
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { ReactComponent as NoPendingFriends } from "assets/svgs/noPendingFriends.svg";
import { ReactComponent as NotFound } from "assets/svgs/friends.svg";
import dataService from 'api/data.service';

const MyPeople = () => {
  const { t } = useTranslation();
  const [friends, setFriends] = useState([]);
  const [allFriends, setAllFriends] = useState([]); // Para almacenar todos los amigos
  const [pendingRequests, setPendingRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchFriends();
    fetchPendingRequests();
  }, []);

  const fetchFriends = async () => {
    try {
      const data = await dataService.getFriends(1, 10, '');
      setFriends(data.resources);
      setAllFriends(data.resources); // Guarda todos los amigos
    } catch (error) {
      console.error('Error fetching friends:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingRequests = async () => {
    try {
      const data = await dataService.getPendingFriendRequests();
      setPendingRequests(data.resources);
    } catch (error) {
      console.error('Error fetching pending friend requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = async (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value.length > 2) {
      setLoading(true);
      try {
        const results = await dataService.getFriends(1, 5, value);
        setFriends(results.resources);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setFriends([]);
      } finally {
        setLoading(false);
      }
    } else {
      setFriends(allFriends); // Muestra todos los amigos si el término de búsqueda es menor o igual a 2
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} width="100%" sx={{ minWidth: '300px' }}>
            <VuiBox display="flex" mb={2} width="100%">
              <VuiInput
                placeholder={t('searchFriends')}
                icon={{ component: 'search', direction: 'left' }}
                value={searchTerm}
                onChange={handleSearchChange}
                sx={({ breakpoints }) => ({
                  backgroundColor: 'info.main !important',
                  flex: 1,
                  minWidth: '300px',
                })}
              />
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              {friends.length > 0 ? (
                friends.map((friend) => (
                  <Grid item key={friend.id} xs={12} md={6}>
                    <FriendInfoCard friend={friend} />
                  </Grid>
                ))
              ) : (
                <VuiBox mt={2} textAlign="center">
                  <NotFound style={{ width: '55%', height: '55%' }} />
                  <Typography variant="h5" sx={{ color: 'white !important' }}>{t('notFriends')}</Typography>
                  <Typography variant="h5" sx={{ color: 'white !important' }}>{t('connectPeople')}</Typography>
                </VuiBox>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined" sx={{ padding: 2 }}>
              <CardContent>
                <VuiTypography variant="h5" color="light">{t('pendingFriendRequests')}</VuiTypography>
                <br/>
                {pendingRequests.length > 0 ? (
                  pendingRequests.map((request) => (
                    <Grid item key={request.id} xs={12} mb={1}>
                      <FriendRequestButton
                        userId={request.friend_id}
                        currentUserId={request.user_id}
                        name={request.name}
                        showViewButton="true"
                        avatar={request.avatar}
                      />
                      <br/>
                      <hr/>
                    </Grid>
                  ))
                ) : (
                  <VuiBox mt={2} textAlign="center">
                    <NoPendingFriends style={{ width: '55%', height: '55%' }} />
                    <Typography variant="h5" sx={{ color: 'white !important' }}>{t('noPendingRequests')}</Typography>
                  </VuiBox>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
      {loading && <div>...</div>}
      <Footer />
    </DashboardLayout>
  );
};

export default MyPeople;
