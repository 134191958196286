import React, { useEffect, useState } from 'react';
import dataService from 'api/data.service';
import { CircularProgress, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoData } from 'assets/svgs/noData.svg';
import VuiTypography from 'components/VuiTypography';
import VuiBox from "components/VuiBox";

const AnnouncementsList = () => {
  const { t } = useTranslation();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const data = await dataService.getAnnouncements();
        setAnnouncements(data.resources);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <VuiTypography color="error">{t('error', { error })}</VuiTypography>;
  }

  if (announcements.length === 0) {
    return (
      <VuiBox mt={3} textAlign="center">
        <NoData style={{ width: '50%', height: 'auto', marginBottom: '20px' }} />
        <VuiTypography variant="h5" color="light">{t('noAnnouncements')}</VuiTypography>
      </VuiBox>
    );
  }

  return (
    <VuiBox mt={3}>
      {announcements.map((announcement) => (
        <Card key={announcement.id} sx={{ mb: 6, backgroundColor: 'rgba(173, 216, 330, 0.4)' }}>
          <VuiBox>
            <VuiTypography variant="h6" color="white" fontWeight="bold">{announcement.title}</VuiTypography>
            <VuiTypography variant="body1" color="white">
              {new Date(announcement.published_at).toLocaleDateString()}
            </VuiTypography>
            <VuiTypography
              variant="body2"
              fontWeight="regular"
              color="white"
              dangerouslySetInnerHTML={{ __html: announcement.content }}
            />
          </VuiBox>
        </Card>
      ))}
    </VuiBox>
  );
};

export default AnnouncementsList;
