import { createContext, useContext } from "react";

const Ranking = createContext();

function RankingProvider({ children, value }) {
  return <Ranking.Provider value={value}>{children}</Ranking.Provider>;
}

function useRanking() {
  return useContext(Ranking);
}

export { RankingProvider, useRanking };
