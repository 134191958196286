import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_MEEPLE_FREAKS + '/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

const signUpService = {
  createUser: async (user) => {
    try {
      const response = await api.post('/users/new', { user })
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
};

export default signUpService;
