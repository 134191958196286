import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiAvatar from "components/VuiAvatar";

function DefaultGameCard({ image, label, title, description, actions = [], options = [] }) {
  const gameTitle = title
  const renderOptions = options.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <VuiAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { dark }, functions: { rgba } }) => ({
          border: `${borderWidth[2]} solid ${rgba(dark.focus, 0.5)}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <VuiBox
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <VuiBox
        component="img"
        src={image}
        mb="8px"
        borderRadius="15px"
        sx={({ breakpoints }) => ({
          [breakpoints.up("xl")]: {
            height: "auto",
            maxWidth: "50%",
          },
        })}
      />

      <VuiBox
        sx={({ breakpoints }) => ({
          [breakpoints.only("xl")]: {
            minHeight: "200px",
          },
        })}
      >
        <VuiBox>
          <VuiTypography variant="xxs" color="text" fontWeight="medium" textTransform="capitalize">
            {label}
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={1}>
          <VuiTypography
            variant="h5"
            color="white"
            textTransform="capitalize"
          >
            {title}
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={3} lineHeight={0}>
          <VuiTypography variant="button" fontWeight="regular" color="text">
            <VuiTypography
              variant="button"
              fontWeight="regular"
              color="text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </VuiTypography>
        </VuiBox>
        <VuiBox display="flex" justifyContent="space-between" alignItems="center">
          {actions.map((action, index) => (
            <VuiButton
              key={index}
              component={action.type === "internal" ? Link : "a"}
              to={action.type === "internal" ? action.route : null}
              href={action.type === "external" ? action.route : null}
              target={action.type === "external" ? "_blank" : null}
              rel={action.type === "external" ? "noreferrer" : null}
              variant="outlined"
              size="small"
              color={action.color}
              placeholder={action.label}
              onClick={action.onClick}
            >
              {action.label}
              {action.icon}
            </VuiButton>
          ))}
          <VuiBox display="flex">{renderOptions}</VuiBox>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
}

// Validación de tipos de propiedades
DefaultGameCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]),
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "white",
        "text",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "white",
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultGameCard;
