// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { Box } from '@mui/material';
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { useTranslation } from 'react-i18next';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleIcon from '@mui/icons-material/People';
import TimelineIcon from '@mui/icons-material/Timeline';
import GameIcon from "components/CustomIcons/GameIcon";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { FaStar } from 'react-icons/fa';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function EventInfoCard({ title, description, games = [], latitude, longitude, info, social, sellers, image, address }) {
  const labels = [];
  const values = [];
  const { size } = typography;
  const { t, i18n } = useTranslation();
  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (typeof el === 'string') {
      if (el.match(/[A-Z\s]+/)) {
        const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
        if (uppercaseLetter) {
          const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);
          labels.push(newElement);
        } else {
          labels.push(el);
        }
      } else {
        labels.push(el);
      }
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));


  const renderMap = () => {
    //const mapUrl = `https://www.google.com/maps/embed/v1/view?center=${latitude},${longitude}&zoom=15`;
    const mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}&z=16&output=embed`;
    
    return (
      <div style={{ height: '300px', width: '100%' }}>
        <iframe
          title="Map"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src={mapUrl}
        />
      </div>
    );
  };

  const renderItems = labels.map((label, index) => {
    let iconComponent = null;
    let content = values[index];
    switch (label) {
      case 'date':
        iconComponent = <CalendarTodayIcon />;
        break;
      case 'duration':
        iconComponent = <AccessTimeIcon />;
        break;
      case 'rating':
        iconComponent = <FavoriteIcon />;
        content = (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              {[...Array(5)].map((star, starIndex) => (
                <FaStar
                  key={starIndex}
                  color={starIndex < (values[index]) ? "#ffc107" : "#e4e5e9"}
                />
              ))}
            </Box>
          </Box>
        );
        break;
      case 'completion':
        iconComponent = <TimelineIcon />;
        break;
      case 'game':
        iconComponent = <GameIcon />;
        break;
      case 'players':
        iconComponent = <PeopleIcon />;
        break;
      case 'status':
        iconComponent = <CheckCircleOutlineIcon />;
        content = t(`statuses.${values[index]}`);
        break;
      case 'vacancies':
        iconComponent = <PersonIcon />;
        content = t('availableVacancies', { count: values[index] });
        break;
      case 'location':
        iconComponent = "map";
        content = renderMap()
        break;
      case 'address':
        iconComponent = <LocationOnIcon />;
        break; 
      default:
        break;
    }

    return (
      <VuiBox key={label} display="flex" py={1} pr={2} alignItems="center">
        <VuiBox display="flex" alignItems="center">
          {iconComponent && iconComponent !== "map" && (
            <VuiTypography variant="button" color="text" fontWeight="regular">
              {iconComponent}: &nbsp;
            </VuiTypography>
          )}
          {!iconComponent && (
            <VuiTypography variant="button" color="text" fontWeight="regular">
              {label}: &nbsp;
            </VuiTypography>
          )}
        </VuiBox>
        <VuiTypography variant="button" fontWeight="regular" color="white" display="flex" alignItems="center">
          &nbsp;{content}
        </VuiTypography>
      </VuiBox>
    );
  });

  const renderSocial = social.map(({ link, icon, color }) => (
    <VuiBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color="white"
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </VuiBox>
  ));

  const renderSellers = sellers.map(({ link, icon, color }) => (
    <VuiBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color="white"
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </VuiBox>
  ));
  const squareSize = 250;
  const thumbnailSize = squareSize / Math.ceil(Math.sqrt(games.length));
  const renderGameThumbnails = games.map((game, index) => (
    <Link key={index} to={`/game/${game.id}`} style={{ textDecoration: "none" }} title={game.title}>
      <VuiAvatar
        src={game.thumbnail}
        alt={game.name}
        title={game.name}
        sx={{
          width: `${thumbnailSize}px`,
          height: `${thumbnailSize}px`,
          borderRadius: "8px",
          objectFit: "cover", // Ajusta la imagen al tamaño del contenedor
        }}
      />
    </Link>
  ));

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <VuiBox display="flex" mb="14px" justifyContent="space-between" alignItems="center">
        <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
          {title}
        </VuiTypography>
      </VuiBox>
      {image && image.length > 0 ? (
        <VuiBox
          component="img"
          src={image}
          mb="8px"
          borderRadius="15px"
          sx={({ breakpoints }) => ({
            [breakpoints.up("xl")]: {
              width: "250px",
            },
          })}
        />
      ) : (
        <VuiBox
          mb="8px"
          sx={{
            width: `${squareSize}px`,
            height: `${squareSize}px`,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {renderGameThumbnails}
        </VuiBox>
      )}
      <VuiBox>
        <VuiBox mb={2} lineHeight={1}>
          <VuiTypography variant="button" color="text" fontWeight="regular">
            {description}
          </VuiTypography>
        </VuiBox>
        <VuiBox opacity={0.3}>
          <Divider />
        </VuiBox>
        <VuiBox>
          {renderItems}
          {social.length > 0 && (
            <VuiBox display="flex" py={1} pr={2} color="white">
              <VuiTypography
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                social: &nbsp;
              </VuiTypography>
              {renderSocial}
            </VuiBox>
          )}
          {sellers.length > 0 && (
            <VuiBox display="flex" py={1} pr={2} color="white">
              <VuiTypography
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                {t('buyMe')}: &nbsp;
              </VuiTypography>
              {renderSellers}
            </VuiBox>
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

EventInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  games: PropTypes.arrayOf(PropTypes.object).isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  sellers: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
};

export default EventInfoCard;
