import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import { BsCheckCircleFill } from "react-icons/bs";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import dataService from "api/data.service";
import EventsData from "pages/played_games/components/Events/data"; // Asegúrate de que la ruta sea correcta

function LastEvents() {
  const { t } = useTranslation();
  const [eventsThisMonth, setEventsThisMonth] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dataService.getUserEventsThisMonth();
        setEventsThisMonth(data["resource"]);
      } catch (error) {
        console.error("Error fetching user events this month:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox mb="auto">
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            {t('lastGames')}
          </VuiTypography>
          <VuiBox display="flex" alignItems="center" lineHeight={0}>
            <BsCheckCircleFill color="green" size="15px" />
            <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
              &nbsp;<strong>{t('playedThisMonth', { count: eventsThisMonth })}</strong>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        <EventsData />
      </VuiBox>
    </Card>
  );
}

export default LastEvents;
