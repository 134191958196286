import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiAvatar from "components/VuiAvatar";
import { useTranslation } from 'react-i18next';
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import dataService from 'api/data.service';

function EditEventCard({ image, label, title, description, owner, games = [], actions = [], options = [], event_id, event_status }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const formatDate = (date, t) => {
    const eventDate = new Date(date);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const dateFormatter = new Intl.DateTimeFormat(t('locale'), options);
    const formattedDate = dateFormatter.format(eventDate);
    return formattedDate;
  };
  const squareSize = 100;
  const thumbnailSize = squareSize / Math.ceil(Math.sqrt(games.length));
  const renderGameThumbnails = games.map((game, index) => (
    <VuiAvatar
      key={index}
      src={game.thumbnail}
      alt={`Game ${index + 1}`}
      sx={{
        width: `${thumbnailSize}px`,
        height: `${thumbnailSize}px`,
        borderRadius: "8px",
        objectFit: "cover", // Ajusta la imagen al tamaño del contenedor
      }}
    />
  ));

  return (
    <VuiBox
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <VuiBox
        sx={({ breakpoints }) => ({
          display: "flex",
          alignItems: "center",
          [breakpoints.only("xl")]: {
            minHeight: "200px",
          },
        })}
      >
        {image && image.length > 0 ? (
          <VuiBox
            component="img"
            src={image}
            mr={2}
            borderRadius="15px"
            sx={({ breakpoints }) => ({
              height: "auto",
              maxWidth: "5%",
              [breakpoints.up("xl")]: {
                maxWidth: "5%",
              },
            })}
          />
        ) : (
          <VuiBox
            mr={2}
            sx={{
              width: `${squareSize}px`,
              height: `${squareSize}px`,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {renderGameThumbnails}
          </VuiBox>
        )}
        <VuiBox>
          <VuiBox mb={1}>
            <VuiTypography
              variant="h5"
              color="white"
            >
              {title}
            </VuiTypography>
          </VuiBox>
          {description && description.length > 0 && (
            <VuiBox mb={1} lineHeight={0}>
              <VuiTypography variant="button" fontWeight="regular" color="text">
                <VuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </VuiTypography>
            </VuiBox>
          )}
        </VuiBox>
      </VuiBox>
      {actions && actions.length > 0 && (
        <VuiBox display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          {actions.map((action, index) => (
            <VuiButton
              key={index}
              component={action.type === "internal" ? Link : "a"}
              to={action.type === "internal" ? action.route : null}
              href={action.type === "external" ? action.route : null}
              target={action.type === "external" ? "_blank" : null}
              rel={action.type === "external" ? "noreferrer" : null}
              variant="outlined"
              size="small"
              color={action.color}
              placeholder={action.label}
              onClick={action.onClick}
            >
              {action.label}
              {action.icon}
            </VuiButton>
          ))}
          <VuiBox display="flex">{renderOptions}</VuiBox>
        </VuiBox>
      )}
    </VuiBox>
  );
}

EditEventCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    birthday: PropTypes.string.isRequired,
    about_me: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    settings: PropTypes.any,
    collection_count: PropTypes.number.isRequired,
    friends_count: PropTypes.number.isRequired,
    events_count: PropTypes.number.isRequired,
    give_me_the_money: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
  }),
  games: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]),
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "white",
        "text",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "white",
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  event_id: PropTypes.number.isRequired,
};

export default EditEventCard;
