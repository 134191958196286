import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiAvatar from "components/VuiAvatar";
import { useTranslation } from 'react-i18next';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ShareButton from 'components/CustomButtons/ShareButton'; 
import JoinEventButton from 'utils/Buttons/EventButtons/JoinEventButton'; 
import VuiBadge from "components/VuiBadge";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import dataService from 'api/data.service';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FaStar } from "react-icons/fa";

function HeaderEventCard({ image, label, title, description, owner, games = [], actions = [], options = [], event_id, join_status, user, event_status, event_bookmarked, user_rating }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const formatDate = (date, t) => {
    const eventDate = new Date(date);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const dateFormatter = new Intl.DateTimeFormat(t('locale'), options);
    const formattedDate = dateFormatter.format(eventDate);
    return formattedDate;
  };
  const squareSize = 100;
  const thumbnailSize = squareSize / Math.ceil(Math.sqrt(games.length));
  const renderGameThumbnails = games.map((game, index) => (
    <VuiAvatar
      key={index}
      src={game.thumbnail}
      alt={`Game ${index + 1}`}
      sx={{
        width: `${thumbnailSize}px`,
        height: `${thumbnailSize}px`,
        borderRadius: "8px",
        objectFit: "cover", // Ajusta la imagen al tamaño del contenedor
      }}
    />
  ));
  
  const [bookmarked, setBookmarked] = React.useState(event_bookmarked);
  const [loading, setLoading] = useState(false);

  const handleBookmark = async () => {
    try {
      await dataService.bookmarkEvent(event_id, bookmarked ? 'unbookmark' : 'bookmark');
      setBookmarked(!bookmarked);
    } catch (error) {
    }
  };

  const handleEditEvent = async () => {
    history.push(`/edit-event/${event_id}`);
  };

  const handleEventScores = async () => {
    history.push(`/event-scores/${event_id}`);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmCancel = async () => {
    try {
      setOpen(false);
      setLoading(true);
      await dataService.cancelEvent(event_id);
      history.push(`/event/${event_id}`);
      history.go();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmFinish = async () => {
    try {
      setOpen(false);
      setLoading(true);
      await dataService.finishEvent(event_id);
      history.push(`/event/${event_id}`);
      history.go();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmFinishAndRepeat = async () => {
    try {
      setOpen(false);
      setLoading(true);
      const data = await dataService.finishAndRepeatEvent(event_id);
      history.push(`/edit-event/${data.resource.id}`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const StarRating = ({ eventId, initialRating, onRatingChange }) => {

    const [rating, setRating] = useState(initialRating);
    const [hover, setHover] = useState(null);

    const handleRating = async (newRating) => {
      try {
        setRating(newRating);
        setLoading(true);
        const data = await dataService.rateEvent(eventId, newRating);
        setRating(newRating);
        history.push(`/event/${eventId}`);
        history.go();
      } catch (error) {
      } finally {
        setLoading(false);
        setRating(newRating);
      }
    };

    return (
      <VuiBox display="flex" alignItems="center">
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;

          return (
            <FaStar
              key={index}
              size={24}
              color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
              onClick={() => handleRating(ratingValue)}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
              style={{ cursor: "pointer", transition: "color 200ms" }}
            />
          );
        })}
      </VuiBox>
    );
  }

  return (
    <VuiBox
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <VuiBox
        sx={({ breakpoints }) => ({
          display: "flex",
          alignItems: "center",
          [breakpoints.only("xl")]: {
            minHeight: "200px",
          },
        })}
      >
        {image && image.length > 0 ? (
          <VuiBox
            component="img"
            src={image}
            mr={2}
            borderRadius="15px"
            sx={({ breakpoints }) => ({
              height: "auto",
              maxWidth: "5%",
              [breakpoints.up("xl")]: {
                maxWidth: "5%",
              },
            })}
          />
        ) : (
          <VuiBox
            mr={2}
            sx={{
              width: `${squareSize}px`,
              height: `${squareSize}px`,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {renderGameThumbnails}
          </VuiBox>
        )}
        <VuiBox>
          <VuiBox mb={1}>
            <VuiTypography
              variant="h5"
              color="white"
            >
              {title}
            </VuiTypography>
          </VuiBox>
          {description && description.length > 0 && (
            <VuiBox mb={1} lineHeight={0}>
              <VuiTypography variant="button" fontWeight="regular" color="text">
                <VuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </VuiTypography>
            </VuiBox>
          )}
          <VuiBox mb={1}>
            <VuiTypography
              variant="button"
              fontWeight="regular"
              color="text"
            >
              {t('organizer')} >{" "}
              <Link to={`/user/${owner.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <VuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {owner.display_name}
                </VuiTypography>
              </Link>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <VuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Link to={`/user/${owner.id}`}>
          <VuiAvatar
            src={owner.avatar}
            alt={owner.display_name}
            size="xs"
            sx={({ borders: { borderWidth }, palette: { dark }, functions: { rgba } }) => ({
              border: `${borderWidth[2]} solid ${rgba(dark.focus, 0.5)}`,
              cursor: "pointer",
              position: "relative",
              mr: 1,
            })}
          />
        </Link>
        <VuiTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarTodayIcon sx={{ mr: 1 }} />
          {t('memberSince')} {formatDate(owner.created_at, t)}. {t('participateCount', { count: owner.events_count })}
        </VuiTypography>
      </VuiBox>
      {event_status === "completed" && join_status === "accepted" && (
        <VuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 3,
          }}
        >
          <VuiTypography
            variant="h6"
            color="white"
          >
            {t('rate')} 
          </VuiTypography>
          <VuiBox sx={{ ml: 1 }}>
            <StarRating 
              eventId={event_id} 
              initialRating={user_rating}
            />
          </VuiBox>
        </VuiBox>
      )}
      <VuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
        }}
      >
        <VuiBox>
          <ShareButton
            title={title}
            text={t('lookAtThisEvent')} 
            url={`${process.env.REACT_APP_DEFAULT_DOMAIN}/event/${event_id}`}
          />
          <VuiButton
            size="small"
            variant="contained"
            color={bookmarked ? "warning" : "light"}
            onClick={handleBookmark}
            startIcon={bookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            sx={{ ml: 1 }}
          >
            {bookmarked ? t('unbookmark') : t('bookmark')}
          </VuiButton>
          {user.id !== owner.id ? (
            event_status === "pending" && (
              <JoinEventButton
                event_id={event_id}
                initialJoinStatus={join_status}
                sx={{ ml: 1 }}
              />
            )
          ) : (
            <>
              <VuiButton
                size="small"
                variant="contained"
                color="dark"
                onClick={handleEventScores}
                sx={{ ml: 1 }}
              >
                {t('scores')}
              </VuiButton>
              {event_status === "pending" && (
                <>
                  <VuiButton
                    size="small"
                    variant="contained"
                    color="info"
                    onClick={handleEditEvent}
                    sx={{ ml: 1 }}
                  >
                    {t('edit')}
                  </VuiButton>
                  <VuiButton
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{ ml: 1 }}
                    onClick={() => handleClickOpen()}
                    disabled={loading}
                  >
                    {t('finishEvent')}
                  </VuiButton>
                </>
              )}
              {/*
              <VuiBadge
                badgeContent={t('owner')}
                size="lg"
                color="dark"
                container
                sx={{ ml: 1 }}
              />
              */}
            </>
          )}
        </VuiBox>
      </VuiBox>
      {actions && actions.length > 0 && (
        <VuiBox display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          {actions.map((action, index) => (
            <VuiButton
              key={index}
              component={action.type === "internal" ? Link : "a"}
              to={action.type === "internal" ? action.route : null}
              href={action.type === "external" ? action.route : null}
              target={action.type === "external" ? "_blank" : null}
              rel={action.type === "external" ? "noreferrer" : null}
              variant="outlined"
              size="small"
              color={action.color}
              placeholder={action.label}
              onClick={action.onClick}
            >
              {action.label}
              {action.icon}
            </VuiButton>
          ))}
          <VuiBox display="flex">{renderOptions}</VuiBox>
        </VuiBox>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#1a237e',
            color: 'white !important',
            border: '1px solid #4a148c',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: 'white !important' }}>
          {t('finishEvent')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'white !important' }}>
            {t('confirmFinishEvent')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleClose} color="light">
            {t('cancel')}
          </VuiButton>
          <VuiButton onClick={handleConfirmCancel} color='error'>
            {t('suspend')}
          </VuiButton>
          <VuiButton onClick={handleConfirmFinish} color='warning'>
            {t('finish')}
          </VuiButton>
          <VuiButton onClick={handleConfirmFinishAndRepeat} color='success'>
            {t('finishAndRepeat')}
          </VuiButton>          
        </DialogActions>
      </Dialog>
      {loading && (
        <VuiBox
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }}
        >
          <CircularProgress color="inherit" />
        </VuiBox>
      )}
    </VuiBox>
  );
}

HeaderEventCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    birthday: PropTypes.string.isRequired,
    about_me: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    settings: PropTypes.any,
    collection_count: PropTypes.number.isRequired,
    friends_count: PropTypes.number.isRequired,
    events_count: PropTypes.number.isRequired,
    give_me_the_money: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  games: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]),
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "white",
        "text",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "white",
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  event_id: PropTypes.number.isRequired,
  join_status: PropTypes.string.isRequired,
  user_rating: PropTypes.number.isRequired,
};

export default HeaderEventCard;
